/**
 * This file was automatically generated by `IconCodeGenerator`.
 * DO NOT MODIFY IT BY HAND. Instead, follow the instructions at `src/shared/joykit/packages/core/icons/README.md`.
 */

import * as React from 'react';

import createSvgIcon from '../createSvgIcon';

import { SvgIconConfig } from '../types';

const config: SvgIconConfig = {
  title: 'Share',
  definitions: {
    '16': {
      viewBox: '0 0 16 16',
      path: (
        <>
          <path
            fillRule="evenodd"
            d="M8.389.945a.55.55 0 0 0-.778 0l-2 2a.55.55 0 0 0 .778.778L7.45 2.661v7.561a.55.55 0 1 0 1.1 0V2.661l1.061 1.061a.55.55 0 0 0 .778-.778l-2-2zm-5.394 6.5c0-.924.749-1.672 1.672-1.672h.556a.55.55 0 0 0 0-1.1h-.556c-1.531 0-2.772 1.241-2.772 2.772v5c0 1.531 1.241 2.772 2.772 2.772h6.667c1.531 0 2.772-1.241 2.772-2.772v-5c0-1.531-1.241-2.772-2.772-2.772h-.555a.55.55 0 0 0 0 1.1h.555c.924 0 1.672.749 1.672 1.672v5c0 .924-.749 1.672-1.672 1.672H4.667c-.924 0-1.672-.749-1.672-1.672v-5z"
            fill="currentColor"
          />
        </>
      )
    }
  }
};

const icon = createSvgIcon(config);
export default icon;
