import useCalculatePrintOrderPrice from '@apps/card/hooks/useCalculatePrintOrderPrice';
import { CardPriceModifiers, PriceConfig } from '@apps/card/routes/CardCustomizer/CardCustomizer.types';
import { LAYOUT_TO_TEMPLATE } from '@apps/card/routes/CardCustomizer/steps/CardDesign/components/cardBackTemplates';
import { CARD_TYPES } from '@apps/card/routes/CardCustomizer/steps/CardDesign/config';
import { StationeryTemplateCategoryEnum } from '@graphql/generated';

export type PriceUpTo = 'cardFront' | 'cardBack' | 'envelope' | 'total';
export type CardPromotion = {
  code: string;
  /**
   * Saved as a percentage, e.g. 0.1 for 10%
   * Only saved on the draft.
   */
  discountPercentage?: Maybe<number>;
  /**
   * Only saved on the print order.
   */
  discountAmountInMinorUnits?: Maybe<number>;
};

const capitalize = (str: string) =>
  str.split(' ').reduce((acc, word) => {
    acc += word.charAt(0).toUpperCase() + word.slice(1);
    return acc;
  }, '');

export const usePriceSummaryController = (
  templateCategory: StationeryTemplateCategoryEnum,
  priceUpTo: PriceUpTo,
  config: PriceConfig,
  customizations: CardPriceModifiers,
  promotion?: Maybe<CardPromotion>,
  salesTax?: Maybe<number>
): Record<'card' | 'back' | 'envelope' | 'shipping' | 'tax', Array<[label: string, value: string | undefined]>> & {
  total: string;
  totalInMinorUnits: number;
  discountedTotal: Maybe<string>;
  discountPercentage: Maybe<number>;
  pricePerCard: string;
} => {
  const {
    pricePerCard,
    pricePerReturnAddress,
    pricePerRecipientAddress,
    totalPricePerQuantity,
    totalPricePerCardShape,
    totalReturnAddressPrice,
    totalRecipientAddressPrice,
    totalDeliveryPrice,
    totalPaperSizePrice,
    totalPaperTypePrice,
    totalEnvelopesPrice,
    totalBackLayoutPrice,
    total,
    discountedTotal,
    discountPercentage,
    totalInMinorUnits,
    tax
  } = useCalculatePrintOrderPrice(templateCategory, priceUpTo, config, customizations, promotion, salesTax);

  const envelopeItems: Array<[label: string, value: string | undefined]> = [
    [`${customizations.quantity} White Signature Envelopes`, totalEnvelopesPrice],
    [`Return Address: ${capitalize(customizations.returnAddress)} ${pricePerReturnAddress}`, totalReturnAddressPrice]
  ];

  return {
    card: [
      [`Quantity: ${customizations.quantity} cards ${pricePerCard} each`, totalPricePerQuantity],
      [`Silhouette: ${capitalize(customizations.shape)}`, totalPricePerCardShape],
      [`Paper Type: ${CARD_TYPES[customizations.paperType].label}`, totalPaperTypePrice],
      ['Size: Classic (5 x 7 inch)', totalPaperSizePrice]
    ],
    back: [[`Layout: ${LAYOUT_TO_TEMPLATE[customizations.backLayout].name}`, totalBackLayoutPrice]],
    envelope: customizations.recipientAddress
      ? [...envelopeItems, [`Recipient Address: ${capitalize(customizations.recipientAddress)} ${pricePerRecipientAddress}`, totalRecipientAddressPrice]]
      : envelopeItems,
    shipping: [[`${capitalize(customizations.delivery || '')} Shipping`.trim(), totalDeliveryPrice]],
    tax: [[`Sales Tax`, tax]],
    total,
    totalInMinorUnits,
    discountedTotal,
    discountPercentage,
    pricePerCard
  };
};
