import React, { useMemo } from 'react';
import { useGetGuestsiteCoupleStoryAndQandasQuery, EventPageType } from '@graphql/generated';

import { Box, BoxProps, Flex, TextV2 } from '@withjoy/joykit';
import { PrettyLink } from '../../components/PrettyLink';
import { ContentColumns } from '../../primitives';
import { FaqList } from '../FaqList';
import { useLayout } from '../../layouts/LayoutProvider';
import { isInIframe } from '@shared/utils/isInIframe';
import { useEventUserRole } from '@shared/components/AuthProvider';
export interface CoupleStoryProps
  extends Readonly<{
      stackByDefault?: boolean;
      qandasTitle?: Maybe<string>;
      eventHandle: string;
    }>,
    BoxProps {}

const CoupleStory = React.forwardRef<HTMLDivElement, CoupleStoryProps>(({ stackByDefault, ...restProps }, ref) => {
  const { eventHandle } = restProps;
  const { layout } = useLayout();
  const isPreviewing = isInIframe();
  const { isAdmin } = useEventUserRole();
  const { data, loading } = useGetGuestsiteCoupleStoryAndQandasQuery({
    variables: { eventHandle: eventHandle, isAdminDashboard: isPreviewing && isAdmin },
    batchMode: 'fast',
    fetchPolicy: 'cache-and-network',
    ssr: false
  });

  const { storyTitle, qandasTitle, storyText, qandas, isBrannan } = useMemo(() => {
    let storyTitle,
      qandasTitle = null;
    data?.eventByName?.pages?.forEach(page => {
      if (page.type === EventPageType.tidbits) {
        qandasTitle = page.pageTitle;
      } else if (page.type === EventPageType.story) {
        storyTitle = page.pageTitle;
      }
    });
    return {
      storyTitle,
      qandasTitle,
      storyText: data?.eventByName?.info.story || '',
      qandas: data?.eventByName?.info.tidbits || [],
      isBrannan: layout === 'brannan'
    };
  }, [data, layout]);

  if (loading) return <div style={{ height: '300px' }}></div>; // height set to prevent divs from jumping around while page loads

  if (!storyText && (!qandas || qandas.length === 0)) {
    return null;
  }

  return (
    <Box ref={ref} {...restProps}>
      <ContentColumns>
        {storyTitle && storyText && (
          <ContentColumns.Column offset={stackByDefault ? undefined : [0, 1]} span={stackByDefault ? 12 : [12, 10]}>
            {isBrannan && (
              <TextV2 data-testid={'story-title'} typographyVariant={'display4'} textAlign="center" marginBottom={9}>
                {storyTitle}
              </TextV2>
            )}
            <TextV2 tagName="div" typographyVariant="body4" wordBreak="break-word">
              <PrettyLink>{storyText}</PrettyLink>
            </TextV2>
          </ContentColumns.Column>
        )}
        {qandas && qandasTitle && !stackByDefault && (
          <ContentColumns.Column offset={stackByDefault ? undefined : [0, 1]} span={stackByDefault ? 12 : [12, 10]} marginTop={10}>
            <Flex flexDirection={'column'} alignItems={'center'} marginBottom={8}>
              <TextV2 data-testid={'story-qandas-title'} typographyVariant={'display4'} textAlign="center">
                {qandasTitle}
              </TextV2>
            </Flex>
            <FaqList data-testid={'story-qandas'} stackByDefault={true} nested={true} eventHandle={eventHandle} tidbits={qandas} />
          </ContentColumns.Column>
        )}
      </ContentColumns>
    </Box>
  );
});

CoupleStory.displayName = 'CoupleStory';

export { CoupleStory };
