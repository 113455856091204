import { Flex, Box, Spinner, TextV2 } from '@withjoy/joykit';
import React from 'react';
import FloatingNotification from '../FloatingNotification';
import { CardDieCut } from '@apps/card/routes/CardCustomizer/CardCustomizer.types';
import { PageScaler } from '@apps/card/routes/CardCustomizer/components/PageScaler';
import { PPI } from '../../../CardDesign/config';
import ENVELOPE_BACK from '../../../Envelope/assets/back.png';
import ENVELOPE_FRONT from '../../../Envelope/assets/front.png';
import { BOX_SHADOW_60_02 } from '@apps/card/boxShadow';
import { CardPreview } from '@apps/card/routes/CardCustomizer/components/CardPreview';

const ENVELOPE_HEIGHT = 5.25 * PPI;
const ENVELOPE_WIDTH = 7.25 * PPI;

interface DraftPreviewProps {
  label?: string;
  notificationText?: string;
  images: {
    cardFront?: string | null;
    cardBack?: string | null;
    envelopFront?: string | null;
    envelopeBack?: string | null;
  };
  isLoading?: boolean;
  withNotification?: boolean;
  shape: CardDieCut;
  showBlankEnvelope?: boolean;
}

interface EnvelopePreviewProps {
  image?: string | null;
  background?: string;
}

export const EnvelopePreview: React.FC<EnvelopePreviewProps> = ({ image, background }) => {
  return (
    <PageScaler key={image}>
      <Box width={ENVELOPE_WIDTH} height={ENVELOPE_HEIGHT} boxShadow={BOX_SHADOW_60_02}>
        <Box height="100%">
          {background && <Box as="img" position="absolute" width={ENVELOPE_WIDTH} height={ENVELOPE_HEIGHT} zIndex={0} src={background} />}
          {image && <Box as="img" position="absolute" width={ENVELOPE_WIDTH} height={ENVELOPE_HEIGHT} zIndex={1} src={image} />}
        </Box>
      </Box>
    </PageScaler>
  );
};

const DraftPreview: React.FC<DraftPreviewProps> = ({ images, isLoading, withNotification = true, shape, showBlankEnvelope = false, label = '', notificationText = '' }) => {
  return (
    <Flex width={5 * PPI} flexDirection="column" rowGap={5} alignItems="center" justifyContent="center" marginX="auto">
      {!isLoading && label && (
        <TextV2 color="#999999" fontSize="12px" fontWeight={600}>
          {label}
        </TextV2>
      )}
      <Flex flexDirection="column" rowGap={10} alignItems="center" justifyContent="center" width="100%" position="relative">
        {!isLoading && images?.cardFront && <CardPreview image={images.cardFront} shape={shape} />}
        {!isLoading && images?.cardBack && <CardPreview image={images.cardBack} shape={shape} />}
        {!isLoading && (images?.envelopFront || showBlankEnvelope === true) && <EnvelopePreview image={images?.envelopFront} background={ENVELOPE_FRONT} />}
        {!isLoading && (images?.envelopFront || showBlankEnvelope === true) && <EnvelopePreview image={images?.envelopeBack} background={ENVELOPE_BACK} />}
        {!isLoading && withNotification && <FloatingNotification text={notificationText} />}
        {isLoading && <Spinner />}
      </Flex>
    </Flex>
  );
};

export default DraftPreview;
