import React from 'react';
import { Link } from '@react-router';
import { Flex, TextV2, ButtonV2 } from '@withjoy/joykit';
import { useGetStationeryPrintOrderByOrderNumberQuery, StationeryTemplateCategoryEnum } from '@graphql/generated';
import { TempSpinner } from '@shared/components/TempSpinner';
import { useRouterHelper } from '@shared/core';
import { routePaths } from '@apps/serviceCenter/route.paths';
import { PRODUCT_OFFERINGS } from '@apps/card/routes/Dashboard/components/ConciergeServiceSection/ConciergeServiceSection.config';

interface PrintOrderSearchByOrderNumberProps {
  printOrderNumber: string;
}

export const PrintOrderSearchByOrderNumber = (props: PrintOrderSearchByOrderNumberProps) => {
  const { printOrderNumber } = props;
  const { buildPath } = useRouterHelper();
  const { data, loading } = useGetStationeryPrintOrderByOrderNumberQuery({
    variables: {
      orderNumber: printOrderNumber
    },
    batchMode: 'fast',
    skip: !printOrderNumber
  });

  const queryData = data?.stationeryPrintOrderByOrderNumber;

  if (loading) return <TempSpinner />;

  if (!queryData) return null;

  const conciergeSuiteName = PRODUCT_OFFERINGS.find(({ identifier }) => identifier === queryData.designSetName)?.longName;
  const category = conciergeSuiteName ? StationeryTemplateCategoryEnum.invitation : queryData.cardDraft?.stationeryTemplate.category;

  return (
    <Flex justifyContent="center" alignItems="flex-start" flexDirection="column" rowGap="10px" paddingTop={6} paddingX={6}>
      <TextV2>Order Number: {printOrderNumber}</TextV2>
      <TextV2>ID: {queryData.id}</TextV2>
      <TextV2>Price: ${queryData.priceInMinorUnits / 100}</TextV2>
      <TextV2>Quantity: {queryData.quantity}</TextV2>
      {queryData.eventId && <TextV2>Event ID: {queryData.eventId}</TextV2>}
      <TextV2>Owner ID: {queryData.ownerId}</TextV2>
      {queryData.cardDraft?.stationeryTemplate.id && <TextV2>Template ID: {queryData.cardDraft.stationeryTemplate.id}</TextV2>}
      {queryData.cardDraft?.stationeryTemplate.themeId && <TextV2>Template Theme ID: {queryData.cardDraft.stationeryTemplate.themeId}</TextV2>}
      {category && <TextV2>Template Category: {category}</TextV2>}
      {conciergeSuiteName && <TextV2>Concierge Suite Name: {conciergeSuiteName}</TextV2>}
      <TextV2>Delivery Method: {queryData.deliveryMethod}</TextV2>
      <TextV2>Sales Order ID: {queryData.printPayment?.salesOrderId}</TextV2>
      <TextV2>Status: {queryData.currentStatus}</TextV2>
      <TextV2>Created At: {queryData.createdAt}</TextV2>
      {queryData.eventId && (
        <ButtonV2 as={Link} to={`${buildPath(routePaths.printOrder.goToPath(printOrderNumber))}?eventId=${queryData.eventId}`} target="_blank">
          Go to Order Detail
        </ButtonV2>
      )}
    </Flex>
  );
};
