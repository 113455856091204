import React from 'react';
import queryString from 'query-string';
import { DialogV2, Flex } from '@withjoy/joykit';
import { useTranslation } from '@shared/core/i18n';
import { ResponsiveMarginProps } from '@withjoy/joykit/styled-system';
import { BookingAssistantItemWrapper, dialogOverridesDesktop, dialogOverridesMobile } from './BookingAssistant.styles';
import { SpacingStack } from '@withjoy/joykit';
import { ReactComponent as Airbnb } from './assets/airbnb.svg';
import { ReactComponent as Booking } from './assets/booking.svg';
import { ReactComponent as Kayak } from './assets/kayak.svg';
import { ReactComponent as Hotels } from './assets/hotels.svg';
import { useAuth } from '@shared/components/AuthProvider';
import { config } from '@static/js/env.config';
import { useResponsive } from '@shared/utils/hooks/useResponsive';

export interface PlaceProps {
  id: 'airbnb' | 'booking' | 'kayak' | 'hotels';
  site: string;
  logo: React.ReactNode;
}

export interface BookingAssistantProps
  extends Readonly<{
      address: Maybe<string>;
      bookingType: Maybe<string>;
      eventId: string;
      guests: number;
      startDate?: number;
      endDate?: number;
      placeId: Maybe<string>;
      lng: Maybe<number> | undefined;
      lat: Maybe<number> | undefined;
      timezone: Maybe<string>;
      isOpen: boolean;
      onClose: () => void;
      onProviderClicked?: (checkIn: Maybe<string>, checkOut: Maybe<string>, guests: Maybe<string>, url: Maybe<string>, provider: Maybe<string>) => void;
    }>,
    ResponsiveMarginProps {}

const useBookingAssistantTranslations = () => {
  const { t, t2 } = useTranslation('bookingAssistant');

  const title = t2('title');

  return {
    title,
    t
  };
};

export const places: PlaceProps[] = [
  {
    id: 'airbnb',
    site: 'airbnb.com',
    logo: <Airbnb />
  },
  {
    id: 'booking',
    site: 'booking.com',
    logo: <Booking />
  },
  {
    id: 'kayak',
    site: 'kayak.com',
    logo: <Kayak />
  },
  {
    id: 'hotels',
    site: 'hotels.com',
    logo: <Hotels />
  }
];

const BookingAssistantContent: React.FC<BookingAssistantProps> = ({
  address,
  bookingType,
  eventId,
  guests,
  startDate,
  endDate,
  placeId,
  lng,
  lat,
  timezone,
  isOpen,
  onClose,
  onProviderClicked
}) => {
  const { currentUser } = useAuth();
  const { t, title } = useBookingAssistantTranslations();
  const [isMobile] = useResponsive({ values: { mobile: true, tablet: false } });

  const handleClick = (provider: PlaceProps['id']) => () => {
    const path = queryString.stringifyUrl({
      url: `${config.redirectServiceUri}/travelredirect`,
      query: {
        provider,
        guests: `${guests}`,
        dtstart: `${startDate}`,
        dtend: `${endDate}`,
        tzid: timezone ?? '',
        address: address ?? '',
        placeId: placeId ?? '',
        lng: `${lng}`,
        lat: `${lat}`,
        bookingType: bookingType ?? '',
        platform: 'web',
        eventId: eventId || '',
        ...(currentUser?.profile?.id ? { userId: currentUser.profile.id } : {})
      }
    });

    window.open(path, '_blank');
    onClose();
    onProviderClicked && onProviderClicked(new Date(startDate || '').toString(), new Date(endDate || '').toString(), guests.toString(), path, provider);
  };

  const overrides = isMobile ? dialogOverridesMobile : dialogOverridesDesktop;

  return (
    <DialogV2 isOpen={isOpen} onClose={onClose} enableDividers={true} overrides={overrides} size="sm">
      <DialogV2.Header typographyVariant="hed4" textAlign="center">
        {title}
        <DialogV2.CloseButton zIndex="modal" />
      </DialogV2.Header>
      <DialogV2.Body>
        <Flex as="ul" padding={'0px 32px 40px 32px'} flexDirection="column" justifyContent="center">
          <SpacingStack spacing={2} as="ul" alignItems={'center'}>
            {places.map(place => (
              <BookingAssistantItemWrapper aria-label={t('placeTitle')({ site: place.site })} key={place.id} onClick={handleClick(place.id)}>
                {place.logo}
              </BookingAssistantItemWrapper>
            ))}
          </SpacingStack>
        </Flex>
      </DialogV2.Body>
    </DialogV2>
  );
};

const BookingAssistant = (props: BookingAssistantProps) => {
  return <BookingAssistantContent {...props} />;
};

BookingAssistant.displayName = 'BookingAssistant';

export { BookingAssistant };
