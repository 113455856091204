import { Flex, IconV2, TextV2 } from '@withjoy/joykit';
import React from 'react';
import { MapMarker, OrderReceipt, InfoStar } from '@withjoy/joykit/icons';

type IconVariant = 'order' | 'pin' | 'info';

interface OrderInfoProps {
  title: string;
  description: string;
  iconVariant: IconVariant;
}

const icons: Record<IconVariant, React.ReactElement> = {
  pin: <MapMarker />,
  order: <OrderReceipt />,
  info: <InfoStar />
};

const OrderInfo: React.FC<OrderInfoProps> = props => {
  return (
    <Flex>
      <IconV2 size={16} marginRight={3}>
        {icons[props.iconVariant]}
      </IconV2>
      <Flex flexDirection="column" rowGap={2}>
        <TextV2 typographyVariant="button1">{props.title}</TextV2>
        <TextV2 typographyVariant="body1" color="mono12" whiteSpace="pre-line">
          {props.description}
        </TextV2>
      </Flex>
    </Flex>
  );
};

export default OrderInfo;
