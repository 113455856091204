import { capitalize, omit } from 'lodash-es';
import { CardAddress, CardDieCut } from './routes/CardCustomizer/CardCustomizer.types';

export const isValidCardAddress = (address?: CardAddress) => {
  if (!address) {
    return false;
  }

  return Object.values(omit(address, ['address2'])).every(Boolean);
};

export const templateToTitle = ({ name, themeId }: { name?: string | null | undefined; themeId: string }) => {
  if (name) return name;
  return themeId
    .split('_')
    .map(capitalize)
    .join(' ')
    .replace(/ Iv$/, ' IV')
    .replace(/ Iii$/, ' III')
    .replace(/ Ii$/, ' II')
    .replace(/ A /, ' a ')
    .replace(/ And /, ' & ')
    .replace(/ In /, ' in ')
    .replace(/ Of /, ' of ');
};

export const cardDieCutToShapeConfig = (shape: CardDieCut) => {
  if (shape === CardDieCut.rounded) {
    const radius = `${54 / 16}em`;
    return {
      borderRadius: `${radius} ${radius} ${radius} ${radius}`,
      clipPath: `inset(0 0 round ${radius} ${radius} ${radius} ${radius})`
    };
  }

  if (shape === CardDieCut.arch) {
    const radius = '11.25em';
    return {
      borderRadius: `${radius} ${radius} 0 0`,
      clipPath: `inset(0 0 round ${radius} ${radius} 0 0)`
    };
  }

  return {
    borderRadius: '0',
    clipPath: 'inset(0 0 round 0 0 0 0)'
  };
};
