import { useGuestSiteTelemetry } from '@apps/guest/GuestSite.telemetry';
import { GraphQLError } from 'graphql';
import { useCallback } from 'react';
import { EventPageFragment, useGetEventSessionQuery } from '@graphql/generated';
import { useGuestSitePageChanged } from '../../common/utils/useGuestSitePageChanged';
import { ReservedRoomBlocks } from '../../widgets/Custom/Custom.types';

export const useLayoutAlohaController = ({
  id,
  eventHandle,
  pages,
  reservedRoomBlocksData
}: {
  id: string;
  eventHandle: string;
  pages: Array<EventPageFragment>;
  reservedRoomBlocksData?: ReservedRoomBlocks;
}) => {
  const telemetry = useGuestSiteTelemetry();
  useGuestSitePageChanged(pages);
  const { data: sessionData } = useGetEventSessionQuery({ variables: { eventHandle }, batchMode: 'fast', ssr: false });

  const hasUnlocked = sessionData?.eventByName?.activeSession.hasUnlocked ?? false;

  // MENU
  const handleMenuClicked = useCallback(
    (pageClicked: string) => {
      telemetry.navMenuItemClicked(pageClicked);
    },
    [telemetry]
  );

  const handleMenuOpenedChange = useCallback(
    (opened: boolean) => {
      telemetry.navMenuOpenedChange(opened ? 'opened' : 'closed');
    },
    [telemetry]
  );

  // EDIT PHOTO ACTIONS

  const telemetryEditPhotoActions = {
    onChangePhotoButtonClicked: () => telemetry.photoEditorChangePhotoClicked(),
    onEditPhotoOpenedChange: (opened: boolean) => telemetry.photoEditorOpened(opened ? 'opened' : 'closed'),
    onFocusPointChange: (prevPoint: unknown, newPoint: unknown) => telemetry.photoEditorUpdateFocus(prevPoint, newPoint),
    onFocusPointChangeError: (error: unknown) => telemetry.trackError('FocusPointChangeError', error)
  };

  // WELCOME SECTION
  const handleWelcomeRSVPButtonClicked = useCallback(() => {
    telemetry.welcomeRSVPButtonClicked();
  }, [telemetry]);

  const handleWelcomeOnNotYouButtonClicked = useCallback(() => {
    telemetry.welcomeNotYouButtonClicked();
  }, [telemetry]);

  const handleWelcomeOnNotYouErrorRequest = useCallback(
    (err: GraphQLError) => {
      telemetry.trackError('WelcomeNotYouError', err);
    },
    [telemetry]
  );

  const handleWelcomeViewDetailsButtonClicked = useCallback(() => {
    telemetry.welcomeViewDetailsButtonClicked();
  }, [telemetry]);

  //Footer Section
  const handleFooterButtonClicked = useCallback(
    (item: string) => {
      telemetry.footerItemClicked(item);
    },
    [telemetry]
  );

  return {
    handleMenuClicked,
    handleWelcomeRSVPButtonClicked,
    handleWelcomeViewDetailsButtonClicked,
    handleFooterButtonClicked,
    handleMenuOpenedChange,
    handleWelcomeOnNotYouErrorRequest,
    handleWelcomeOnNotYouButtonClicked,
    pages,
    telemetryEditPhotoActions,
    hasUnlocked
  };
};
