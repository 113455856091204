import React, { useCallback, useMemo, useRef } from 'react';
import { GoogleMap } from '@react-google-maps/api';
import { MapLoading, MapLoadingDot, mapUIstyles, styles } from './JoyInteractableMap.styles';
import './JoyInteractableMap.css';
import { centerAndZoom } from './JoyInteractableMap.utils';
import { debounce } from 'lodash-es';
import { useJoyInteractableMapContext } from './JoyInteractableMap.provider';

export const DEFAULT_MAP_SEARCH_RADIUS_MILES = 10;

export interface JoyInteractableMapProps {
  id?: string;
  center: google.maps.LatLngLiteral;
  radiusMiles?: number;
  bounds?: google.maps.LatLngBounds;
  autoCenterAndZoom?: boolean;
  zoom?: number;
  gestureHandling?: 'cooperative' | 'greedy' | 'none';
  onClick?: (e: MouseEvent) => void;
  onCenterChanged?: (lat: number, lng: number, distanceToPrevCenter: number, zoom?: number, bounds?: google.maps.LatLngBounds) => void;
  isCenterChangedDebounced?: boolean;
  onDragEnd?: () => void;
  onZoomChanged?: (zoom?: number, prevZoom?: number) => void;
}

export const JoyInteractableMap: React.FC<JoyInteractableMapProps> = ({
  center,
  radiusMiles = DEFAULT_MAP_SEARCH_RADIUS_MILES,
  bounds,
  autoCenterAndZoom,
  zoom = 15,
  gestureHandling = 'cooperative',
  onClick,
  onCenterChanged,
  isCenterChangedDebounced,
  onZoomChanged,
  onDragEnd,
  children,
  ...props
}) => {
  const { map, setMap, loadingPlaces } = useJoyInteractableMapContext();

  const prevCenter = useRef<google.maps.LatLngLiteral>(center);
  const prevZoom = useRef<number | undefined>(zoom);

  // After change center - if JoyInteractableMap re-render then map get reset center to original
  const handleCenterChanged = useCallback(() => {
    const centerUpdated = map?.getCenter();
    if (centerUpdated) {
      const lat = centerUpdated.lat();
      const lng = centerUpdated.lng();

      const distanceToPrevCenter = google.maps.geometry.spherical.computeDistanceBetween(centerUpdated, prevCenter.current);

      prevCenter.current = {
        lat,
        lng
      };

      if (Number(lat.toFixed(7)) !== Number(center.lat.toFixed(7)) || Number(lng.toFixed(7)) !== Number(center.lng.toFixed(7))) {
        onCenterChanged?.(centerUpdated.lat(), centerUpdated.lng(), distanceToPrevCenter);
      }
    }
  }, [center.lat, center.lng, map, onCenterChanged]);

  const debouncedHandleCenterChanged = useMemo(() => debounce(handleCenterChanged, 1000), [handleCenterChanged]);

  const handleZoomChanged = useCallback(() => {
    const zoom = map?.getZoom();

    onZoomChanged?.(zoom, prevZoom.current);
    prevZoom.current = zoom;
  }, [map, onZoomChanged]);

  return (
    <GoogleMap
      mapContainerStyle={{
        width: '100%',
        height: '100%'
      }}
      onLoad={map => {
        if (autoCenterAndZoom) {
          if (bounds) {
            map.fitBounds(bounds);
            map.setCenter(center);
            map.setZoom(zoom);
          }
          if (!bounds) {
            centerAndZoom(center, radiusMiles, map);
          }
        }
        setMap(map);
      }}
      options={{
        styles: mapUIstyles,
        disableDefaultUI: true,
        gestureHandling
      }}
      clickableIcons={false}
      center={center}
      onClick={e => {
        onClick?.(e.domEvent as MouseEvent);
      }}
      zoom={zoom}
      onCenterChanged={isCenterChangedDebounced ? debouncedHandleCenterChanged : handleCenterChanged}
      onZoomChanged={handleZoomChanged}
      {...props}
    >
      <MapLoading __css={styles.mapLoading(loadingPlaces)}>
        <MapLoadingDot __css={styles.mapLoadingDot} />
        <MapLoadingDot __css={styles.mapLoadingDot} />
        <MapLoadingDot __css={styles.mapLoadingDot} />
      </MapLoading>
      {children}
    </GoogleMap>
  );
};
