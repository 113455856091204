import React, { Dispatch, SetStateAction, useEffect, useRef } from 'react';

import { LayoutBaseProps } from '../layout.types';
import { Flex } from '@withjoy/joykit';

import { FrameWrapper } from './components/FrameWrapper';
import { MainFrame } from './components/MainFrame';
import { AuxFrame, AuxFrameProvider } from './components/AuxFrame';
import { Body } from './components/Body';
import { alohaConfig, useAlohaDesign } from './LayoutAloha.theme';
import { WelcomeCard } from './components/WelcomeCard';
import WelcomeVideo from '../../widgets/WelcomeVideo';
import { Footer } from '@apps/guest/packages/layout-engine/components/Footer';
import { alohaFooterContainerStyles } from '@apps/guest/packages/layout-engine/components/Footer/Footer.styles';
import { useMediaQuery } from '@withjoy/joykit/utils';
import { useResponsive } from '@shared/utils/hooks/useResponsive';
import { PageContainer, CustomPageContainer, StyledFlexAloha } from './LayoutAloha.styles';
import { isInIframe } from '@shared/utils/isInIframe';
import EventMenu from './components/EventMenu/EventMenu';
import { useLayoutAlohaController } from './LayoutAloha.controller';
import { getEventMenuPropsFromEvent } from '@apps/guest/packages/layout-engine/layouts/layout.utils';
import { useAlohaScroll } from '@apps/guest/packages/layout-engine/layouts/LayoutAloha/hooks/useAlohaScroll';
import { PagesRefs } from '@apps/guest/packages/layout-engine/layouts/LayoutAloha/LayoutAloha.types';
import { PreloadImages } from '@apps/guest/packages/layout-engine/layouts/LayoutAloha/components/PreloadImages/PreloadImages';
import { ReservedRoomBlocks } from '../../widgets/Custom/Custom.types';
import { useGuestSiteState } from '@apps/guest/routes/GuestSite/GuestSite.state.provider';

export interface LayoutAlohaProps extends Readonly<LayoutBaseProps> {
  setLoadingTheme?: Maybe<Dispatch<SetStateAction<boolean>>>;
  setIsLastPageVisible?: (value: boolean) => void;
  reservedRoomBlocksData?: ReservedRoomBlocks;
}

const { simple } = alohaConfig.applicatorHtmlProps;

const LayoutAloha: React.FC<LayoutAlohaProps> = ({ eventHandle, event, setLoadingTheme, setIsLastPageVisible, reservedRoomBlocksData, ...restProps }) => {
  const {
    id,
    pages,
    photo,
    mobilePhoto,
    video,
    info: { eventDisplayName, greeting }
  } = event;

  const { isSmallScreen, loading, CustomStyle } = useAlohaDesign(event.eventDesign);
  const isPortrait = useMediaQuery('(orientation: portrait)');
  const [isMobile] = useResponsive({ values: { mobile: true, tablet: false } });
  const useMobilePhoto = isPortrait && isMobile;
  const homeRef = useRef<HTMLDivElement>(null);
  const isPreviewing = isInIframe();
  const containerRef = useRef(null);
  const pagesRef: Record<string, PagesRefs> = {};
  const { enabledAdminGuestSiteBannerDialog } = useGuestSiteState();

  useAlohaScroll(pages, pagesRef, containerRef.current, setIsLastPageVisible);

  const {
    handleMenuClicked,
    handleWelcomeRSVPButtonClicked,
    handleWelcomeViewDetailsButtonClicked,
    handleFooterButtonClicked,
    handleMenuOpenedChange,
    handleWelcomeOnNotYouButtonClicked,
    handleWelcomeOnNotYouErrorRequest,
    telemetryEditPhotoActions
  } = useLayoutAlohaController({ id, eventHandle, pages, reservedRoomBlocksData });

  useEffect(() => {
    setLoadingTheme && setLoadingTheme(loading);
  }, [loading, setLoadingTheme]);

  return (
    <FrameWrapper>
      {CustomStyle && <CustomStyle />}
      <PreloadImages pages={pages} />
      <AuxFrameProvider isSmallScreen={isSmallScreen} eventId={id} primaryPhoto={useMobilePhoto ? mobilePhoto : photo} pages={pages} isPreviewing={isPreviewing}>
        <StyledFlexAloha ref={containerRef} loading={loading} isPreviewing={isPreviewing} className="aloha-scrollable">
          <div id="photo-welcome" ref={homeRef} />
          {!isMobile && (
            <AuxFrame telemetryPhotoEditor={telemetryEditPhotoActions} title={eventDisplayName} subtitle={greeting} eventHandle={eventHandle}>
              <EventMenu
                eventProps={getEventMenuPropsFromEvent(event)}
                handleMenuOpenedChange={handleMenuOpenedChange}
                handleMenuClicked={handleMenuClicked}
                title={eventDisplayName}
                eventHandle={eventHandle}
                hasBanner={enabledAdminGuestSiteBannerDialog}
              />
            </AuxFrame>
          )}
          <MainFrame>
            {pages.map((page, idx) => {
              if (page.type === 'welcome') {
                const nextPage = idx === pages.length - 1 ? null : pages[idx + 1]; // Get next page if it has
                return (
                  <PageContainer key={page.id} ref={ref => (pagesRef[page.id] = { ref, page })}>
                    <div>
                      {isMobile && (
                        <AuxFrame telemetryPhotoEditor={telemetryEditPhotoActions} title={eventDisplayName} subtitle={greeting} eventHandle={eventHandle}>
                          <EventMenu
                            eventProps={getEventMenuPropsFromEvent(event)}
                            handleMenuOpenedChange={handleMenuOpenedChange}
                            handleMenuClicked={handleMenuClicked}
                            title={eventDisplayName}
                            eventHandle={eventHandle}
                            hasBanner={enabledAdminGuestSiteBannerDialog}
                          />
                        </AuxFrame>
                      )}
                      <WelcomeCard
                        homeRef={homeRef}
                        page={page}
                        nextPageSlug={nextPage?.pageSlug}
                        eventHandle={eventHandle}
                        hideEventCountdown={event.settings.hideEventCountdown}
                        finalizedDate={event.info.finalizedDate}
                        location={event.info.location}
                        onRSVPButtonClicked={handleWelcomeRSVPButtonClicked}
                        onViewDetailsButtonClicked={handleWelcomeViewDetailsButtonClicked}
                        onNotYouButtonClicked={handleWelcomeOnNotYouButtonClicked}
                        onNotYouErrorRequest={handleWelcomeOnNotYouErrorRequest}
                        {...restProps}
                      />
                      {video ? <WelcomeVideo key="welcome-video" video={video} thumbnailUrl={video.thumbnailUrl || page.photo?.url} /> : null}
                    </div>
                  </PageContainer>
                );
              }

              if (page.type === 'custom') {
                return (
                  <CustomPageContainer key={page.id} ref={ref => (pagesRef[page.id] = { ref, page })}>
                    <Body
                      key={page.id}
                      data-testid={`layout-body-${page.pageSlug}`}
                      page={page}
                      event={event}
                      eventHandle={eventHandle}
                      applicatorProps={simple.prop || {}}
                      pageTitle={page.pageTitle}
                    />
                  </CustomPageContainer>
                );
              }

              return (
                <PageContainer key={page.id} ref={ref => (pagesRef[page.id] = { ref, page })}>
                  <Body
                    key={page.id}
                    data-testid={`layout-body-${page.pageSlug}`}
                    page={page}
                    event={event}
                    eventHandle={eventHandle}
                    applicatorProps={simple.prop || {}}
                    pageTitle={page.pageTitle}
                  />
                </PageContainer>
              );
            })}
            <Flex __css={alohaFooterContainerStyles}>
              <Footer onButtonClicked={handleFooterButtonClicked} data-testid={'layout-footer'} />
            </Flex>
          </MainFrame>
        </StyledFlexAloha>
      </AuxFrameProvider>
    </FrameWrapper>
  );
};

LayoutAloha.displayName = 'LayoutAloha';

export { LayoutAloha };
