import React from 'react';
import { SearchEventBy } from './components/SearchEventBy';
import { Flex, Spinner } from '@withjoy/joykit';
import { EventTable } from './components/EventTable';

export const Events = () => {
  const [loadingEvent, setLoadingEvent] = React.useState<boolean>(false);
  const [savingEvent, setSavingEvent] = React.useState<boolean>(false);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [eventFound, setEventFound] = React.useState<any>(null);

  return (
    <>
      {loadingEvent || savingEvent ? (
        <Flex position="absolute" zIndex={1} backgroundColor="rgba(0, 0, 0, 0.1)" justifyContent="center" width="100vw" height="100vh">
          <Spinner />
        </Flex>
      ) : null}
      <SearchEventBy setEventFound={setEventFound} setLoadingEvent={setLoadingEvent} />
      <EventTable event={eventFound} setSavingEvent={setSavingEvent} />
    </>
  );
};
