import { useMemo } from 'react';
import { PointOnMapType, PointsOnMapFragment, ReservedRoomBlockStatus, useGuestEventHotelRoomBlocksQuery, useGuestPageContainerBySlugQuery } from '@graphql/generated';
import { DEFAULT_DESTINATION, DEFAULT_RESERVED_ROOM_BLOCKS } from './Custom.constants';
import { useEventUserRole } from '@shared/components/AuthProvider';
import { isInIframe } from '@shared/utils/isInIframe';

export const useCustomData = (eventId: string, eventHandle: string, pageSlug: string) => {
  const isPreviewing = isInIframe();
  const { isAdmin } = useEventUserRole();

  const { data: hotelRoomBlocksData, loading: hotelRoomBlocksLoading } = useGuestEventHotelRoomBlocksQuery({
    variables: {
      eventId
    },
    fetchPolicy: 'cache-and-network',
    batchMode: 'fast'
  });
  const reservedRoomBlocks = hotelRoomBlocksData?.event?.hotelRoomBlocks?.reservedRoomBlocks?.filter(roomBlock => roomBlock.status === ReservedRoomBlockStatus.active);
  const eventPhoto = hotelRoomBlocksData?.event?.photo?.url ?? '';

  const { data: pageContainerData, loading: pageContainerLoading } = useGuestPageContainerBySlugQuery({
    variables: {
      payload: {
        eventName: eventHandle,
        pageSlug: pageSlug
      },
      isAdminDashboard: isPreviewing && isAdmin
    },
    fetchPolicy: 'cache-and-network',
    batchMode: 'fast'
  });

  const travelMapComponent = pageContainerData?.pageContainerBySlug?.components?.find(component => component.__typename === 'TravelMapComponent');
  const blockOrderingInitial = travelMapComponent && travelMapComponent.__typename === 'TravelMapComponent' ? travelMapComponent?.blockOrdering : [];
  const blockOrdering =
    blockOrderingInitial && blockOrderingInitial?.length === reservedRoomBlocks?.length ? blockOrderingInitial : reservedRoomBlocks?.map(roomBlock => roomBlock.id);

  const loading = hotelRoomBlocksLoading || pageContainerLoading;
  const showDefaultData = !loading && (!reservedRoomBlocks || reservedRoomBlocks.length === 0);
  const pointsOnMap = useMemo(
    () =>
      travelMapComponent && travelMapComponent.__typename === 'TravelMapComponent'
        ? travelMapComponent?.pointsOnMap?.filter((pointOnMap): pointOnMap is Exclude<PointsOnMapFragment, null> => pointOnMap !== null) ?? []
        : [],
    [travelMapComponent]
  );
  const destination = useMemo(() => pointsOnMap?.find(pointOnMap => pointOnMap?.type === PointOnMapType.venue), [pointsOnMap]);
  return {
    loading,
    showDefaultData,
    destination: showDefaultData && !destination ? DEFAULT_DESTINATION : destination,
    eventPhoto,
    reservedRoomBlocks: showDefaultData ? DEFAULT_RESERVED_ROOM_BLOCKS : reservedRoomBlocks,
    blockOrdering,
    pointsOnMap: showDefaultData && pointsOnMap?.length === 0 ? [DEFAULT_DESTINATION] : pointsOnMap
  };
};
