import React, { useState } from 'react';
import { Flex, Box, TextV2, ButtonV2, FabButton } from '@withjoy/joykit';
import { useLayout } from '../../layouts/LayoutProvider';
import { ReservedRoomBlock } from '../../widgets/Custom/Custom.types';
import { StarFilled, Schedule, Tags, Share } from '@withjoy/joykit/icons';
import { useIsTextOverflowing } from './hooks/useTextLineClampOverflow';

import { createDateFromUnformattedString, isSameMonthCheckInCheckOut } from '@apps/admin/common/hooks/useDateUtils';
import { distanceInMiles } from '@shared/utils/distanceInMiles';
import { TextEllipsisWrapper } from '@apps/admin/routes/WebsiteEditor/routes/WebsiteEditorTravel/components/TravelLists/TravelLists.styles';
import { useTranslation } from '@shared/core';
import { useCustomPageTelemetry } from '../../widgets/Custom/Custom.telemetry';
import { withWindow } from '@shared/utils/withWindow';
import { useEventCallback } from '@shared/utils/hooks/useEventCallback';
import { useHotelBookingRoutePaths } from '@apps/guest/routes/HotelBooking/HotelBooking.routes';
import { SkeletonText, SkeletonThumbnail } from '@shared/components/Skeleton';
import { pxToRem } from '@withjoy/joykit/theme';
import { useMediaQuery } from '@withjoy/joykit/utils';
import { useGuestSiteState } from '@apps/guest/routes/GuestSite/GuestSite.state.provider';
import { PointsOnMapFragment } from '@graphql/generated';

interface HotelTileProps extends ReservedRoomBlock {
  locationInfo?: PointsOnMapFragment;
  eventPhoto: string;
  eventHandle: string;
  isFirst: boolean;
  isLast: boolean;
  showDefaultData?: boolean;
  setLocationBeingHovered?: (id: string | undefined) => void;
}

const DEFAULT_HOTEL_IMAGE = 'https://withjoy.com/assets/public/hotelselection/empty-hotel-item.png';

const HotelTile: React.FC<HotelTileProps> = props => {
  const { layout } = useLayout();
  const {
    note,
    displayName,
    photo,
    eventPhoto,
    starRating,
    isFavorite,
    cutoffDate,
    strikeoutPricePerNight,
    pricePerNight,
    checkInDate,
    checkOutDate,
    id,
    isFirst,
    isLast,
    eventHandle,
    showDefaultData,
    setLocationBeingHovered
  } = props;
  const [isCoupleNoteOpen, setIsCoupleNoteOpen] = useState(false);
  const { t } = useTranslation('guestSiteCustom');
  const accTrans = t('accommodations');
  const { roomblock, buildPath } = useHotelBookingRoutePaths();
  const { textRef, isOverflowing } = useIsTextOverflowing(4);
  const isMobile = useMediaQuery(theme => theme.mediaQueries.between(0, { breakpointAlias: 'sm2' }));
  const isMobileOrTablet = useMediaQuery(theme => theme.mediaQueries.between(0, { breakpointAlias: 'md' }));
  const { setShowNoLeadDialog, enabledAdminGuestSiteBannerDialog } = useGuestSiteState();

  const telemetry = useCustomPageTelemetry();
  const telemetryHotelInfo = {
    hotelName: displayName || '',
    isHotelPreferred: isFavorite || false,
    hotelPrice: pricePerNight || undefined,
    hotelStrikeThroughPrice: strikeoutPricePerNight || undefined
  };

  const isBrannan = layout === 'brannan';
  const haveDistanceToVenue = props?.locationInfo?.latitude && props?.locationInfo?.longitude && props?.latitude && props?.longitude;
  const milesToVenue = distanceInMiles(props?.locationInfo?.latitude || 0, props?.locationInfo?.longitude || 0, props?.latitude || 0, props?.longitude || 0).toFixed(1);
  const isNotePresent = note && note?.length > 0;

  const hasShare = Boolean(navigator.share);
  const handleShareBookinglink = useEventCallback(() => {
    if (enabledAdminGuestSiteBannerDialog) {
      telemetry.leadGenDialogPromptedClicked();
      setShowNoLeadDialog(true);
    } else {
      telemetry.shareRoomClicked('accommodations', telemetryHotelInfo);
      withWindow(global => navigator.share({ url: `${global.location.origin}${buildPath(eventHandle || '', roomblock, id)}` }));
    }
  });

  const handleOnSelectRoom = useEventCallback(() => {
    if (enabledAdminGuestSiteBannerDialog) {
      telemetry.leadGenDialogPromptedClicked();
      setShowNoLeadDialog(true);
    } else {
      telemetry.selectRoomClicked('accommodations', telemetryHotelInfo);
      withWindow(global => global.open(`${global.location.origin}${buildPath(eventHandle, roomblock, id)}`, '_blank'));
    }
  });

  const handleCoupleNoteOpen = useEventCallback(() => setIsCoupleNoteOpen(isOpen => !isOpen));
  return (
    <Flex paddingTop={isBrannan ? { _: 8, sm2: 0 } : isFirst ? 0 : 8} paddingBottom={isBrannan && isLast ? (isMobile ? 8 : 0) : 8} width={'100%'} justifyContent="center">
      <Flex maxWidth={isBrannan ? 520 : 500} width={'100%'}>
        <Flex width={'100%'} display={'column'}>
          <Box
            width={'100%'}
            height={250}
            minWidth={56}
            borderRadius={'12px'}
            objectFit={'cover'}
            backgroundSize={'cover'}
            backgroundPosition={`center ${showDefaultData ? 'bottom' : 'center'}`}
            backgroundRepeat={'no-repeat'}
            backgroundImage={`url(${photo?.url || DEFAULT_HOTEL_IMAGE})`}
            display="flex"
            justifyContent="flex-end"
          >
            {isMobile && hasShare && (
              <FabButton
                variant="ghostShadow"
                onClick={handleShareBookinglink}
                top={5}
                right={5}
                width={pxToRem(32)}
                height={pxToRem(32)}
                minWidth={pxToRem(7)}
                minHeight={pxToRem(7)}
                padding={0}
              >
                <Share size="sm" />
              </FabButton>
            )}
          </Box>
          <Flex flexDirection={'column'} width={'100%'} paddingTop={6} paddingBottom={7}>
            <TextV2 width={'100%'} fontFamily={'Inter UI'} fontSize={'18px'} fontWeight={600} lineHeight={'23.4px'} letterSpacing={'-0.432px'}>
              {displayName}
            </TextV2>
            <Flex flexDirection={'row'} paddingTop={1} paddingBottom={7} justifyContent={'space-between'}>
              {haveDistanceToVenue && (
                <TextV2 typographyVariant={'body1'} fontFamily="Inter UI" textDecoration={'none !important'}>
                  {haveDistanceToVenue ? accTrans.milesFromVenue({ miles: milesToVenue }) : ''}
                </TextV2>
              )}
              {starRating && (
                <Flex>
                  <Box paddingRight={1}>
                    <StarFilled size={16} />
                  </Box>
                  <TextV2 typographyVariant={'body1'} alignSelf={'center'} fontFamily="Inter UI">
                    {starRating}
                  </TextV2>
                </Flex>
              )}
            </Flex>

            {isNotePresent ? (
              <Flex flexDirection={'row'} columnGap={5} paddingBottom={6}>
                <Box
                  minWidth={48}
                  width={48}
                  height={48}
                  borderRadius={48}
                  border="2px solid"
                  borderColor="mono3"
                  objectFit={'contain'}
                  backgroundSize={'cover'}
                  backgroundPosition={'center'}
                  backgroundRepeat={'no-repeat'}
                  backgroundImage={`url(${eventPhoto})`}
                ></Box>
                <Flex flexDirection={'column'}>
                  {isFavorite && (
                    <TextV2 typographyVariant={'button1'} fontFamily={'Inter UI'} fontSize={15} fontWeight={700}>
                      {accTrans.favoriteHotel()}
                    </TextV2>
                  )}
                  <Flex flexDirection={'column'}>
                    <TextEllipsisWrapper lineClamp={isCoupleNoteOpen ? 10 : 4} ref={textRef}>
                      <TextV2 fontFamily={'Inter UI'} fontSize={15} fontStyle={'italic'} fontWeight={400} lineHeight={'150%'} letterSpacing={'-0.15px'} color={'mono12'}>
                        {'"'}
                        {note}
                        {'"'}
                      </TextV2>
                    </TextEllipsisWrapper>
                    {isOverflowing && (
                      <span>
                        <TextV2 fontFamily={'Inter UI'} fontSize={15} fontWeight={700} _hover={{ cursor: 'pointer' }} color="mono12" onClick={handleCoupleNoteOpen}>
                          {isCoupleNoteOpen ? `${accTrans.less()}` : `${accTrans.more()}`}
                        </TextV2>
                      </span>
                    )}
                  </Flex>
                </Flex>
              </Flex>
            ) : isFavorite ? (
              <Flex flexDirection={'row'} columnGap={5} paddingBottom={6}>
                <Box
                  minWidth={48}
                  width={48}
                  height={48}
                  borderRadius={48}
                  border="2px solid"
                  borderColor="mono3"
                  objectFit={'contain'}
                  backgroundSize={'cover'}
                  backgroundPosition={'center'}
                  backgroundRepeat={'no-repeat'}
                  backgroundImage={`url(${eventPhoto})`}
                ></Box>
                <Flex flexDirection={'column'} justifyContent="center">
                  <TextV2 typographyVariant={'button1'} fontFamily={'Inter UI'} fontSize={15} fontWeight={700}>
                    {accTrans.favoriteHotel()}
                  </TextV2>
                </Flex>
              </Flex>
            ) : null}
            <Flex flexDirection={'row'} columnGap={5}>
              <Flex>
                <Schedule size={48} />
              </Flex>
              <Flex flexDirection={'column'}>
                <TextV2 fontFamily={'Inter UI'} fontSize={15} fontWeight={600} lineHeight={'22.5px'} letterSpacing={'-0.15px'} paddingBottom={1}>
                  {accTrans.beforeDate({ date: createDateFromUnformattedString(cutoffDate || '').toLocaleDateString('en-US', { month: 'long', day: '2-digit', year: 'numeric' }) })}
                </TextV2>
                <TextV2 typographyVariant={'body1'} fontFamily={'Inter UI'} fontSize={15} fontWeight={400} color={'mono12'}>
                  {accTrans.riskFreeCancellation()}
                </TextV2>
              </Flex>
            </Flex>
          </Flex>
          <Flex flexDirection={'column'} rowGap={6}>
            <Flex flexDirection={'row'} columnGap={5}>
              <Flex>
                <Tags size={'xl'} />
              </Flex>
              <Flex flexDirection={'column'} justifyContent={'center'}>
                <Flex flexDirection={'row'} columnGap={1} paddingBottom={1}>
                  {strikeoutPricePerNight ? (
                    <>
                      <TextV2
                        backgroundSize={'100% 1.5px'}
                        width={'auto'}
                        backgroundImage={'linear-gradient(currentColor, currentColor)'}
                        backgroundPosition={'0% 50%'}
                        backgroundRepeat={'no-repeat'}
                        fontFamily={'Inter UI'}
                        fontSize={15}
                        fontWeight={700}
                        color={'mono10'}
                      >
                        ${strikeoutPricePerNight.toString()}
                      </TextV2>
                      <TextV2 color="mono14" fontFamily={'Inter UI'} fontSize={15} fontWeight={700}>
                        ${pricePerNight}
                      </TextV2>
                    </>
                  ) : (
                    <>
                      <TextV2 color="mono14" fontFamily={'Inter UI'} fontSize={15} fontWeight={700}>
                        ${pricePerNight}{' '}
                      </TextV2>
                    </>
                  )}
                  <TextV2 fontFamily={'Inter UI'}>{accTrans.night()}</TextV2>
                </Flex>
                <TextV2 typographyVariant={'label2'} fontFamily="Inter UI">
                  {createDateFromUnformattedString(checkInDate || '').toLocaleDateString('en-US', { month: 'short', day: '2-digit' })} -{' '}
                  {isSameMonthCheckInCheckOut(checkInDate || '', checkOutDate || '')
                    ? createDateFromUnformattedString(checkOutDate || '').toLocaleDateString('en-US', { day: '2-digit' })
                    : createDateFromUnformattedString(checkOutDate || '').toLocaleDateString('en-US', { month: 'short', day: '2-digit' })}
                  {', '}
                  {createDateFromUnformattedString(checkInDate || '').toLocaleDateString('en-US', { year: 'numeric' })}
                </TextV2>
              </Flex>
            </Flex>
            <ButtonV2
              width="100%"
              intent="neutral"
              shape="rounded"
              fontWeight={600}
              fontSize={16}
              fontFamily={'Inter UI'}
              onMouseEnter={() => !isMobileOrTablet && setLocationBeingHovered?.(id)}
              onMouseLeave={() => !isMobileOrTablet && setLocationBeingHovered?.(undefined)}
              onClick={handleOnSelectRoom}
            >
              {accTrans.selectCTA()}
            </ButtonV2>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};

HotelTile.displayName = 'HotelTile';

const HotelTileSkeleton = () => {
  return (
    <>
      <SkeletonThumbnail
        height={250}
        overrides={{
          PlaceholderRoot: {
            props: {
              width: '100%',
              paddingTop: '75%',
              backgroundColor: 'white',
              borderRadius: '12px !important'
            }
          }
        }}
      />
      <Box width="100%" height={pxToRem(221)} paddingTop={4}>
        <SkeletonText rows={1} width={pxToRem(136)} skeletonHeight={pxToRem(26)} paddingBottom={3} />
        <SkeletonText rows={1} width={pxToRem(250)} skeletonHeight={pxToRem(26)} paddingBottom={3} />
        <SkeletonText rows={1} width={pxToRem(206)} skeletonHeight={pxToRem(42)} paddingBottom={6} />
        <SkeletonText width={'100%'} rows={1} skeletonHeight={pxToRem(40)} />
      </Box>
    </>
  );
};

export { HotelTile, HotelTileSkeleton };
