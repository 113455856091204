import { TextV2 } from '@withjoy/joykit';
import React from 'react';
import { AuxTitleContainer } from './AuxFrameTitle.styles';

interface Props
  extends Readonly<{
    x: 'left' | 'middle' | 'right';
    y: 'top' | 'middle' | 'bottom';
    textLight: boolean;
    title?: Maybe<string>;
    subtitle?: Maybe<string>;
  }> {}

export const AuxFrameTitle: React.FC<Props> = props => {
  const { x, y, textLight, title, subtitle } = props;
  const textAlign = x === 'middle' ? 'center' : undefined;
  return (
    <AuxTitleContainer y={y} x={x} color={textLight ? 'white' : '#333'}>
      {title ? (
        <TextV2 typographyVariant="display8" textAlign={textAlign}>
          {title}
        </TextV2>
      ) : null}
      {subtitle ? (
        <TextV2 typographyVariant="hed4" textAlign={textAlign} marginTop={[5]} whiteSpace="pre-wrap">
          {subtitle}
        </TextV2>
      ) : null}
    </AuxTitleContainer>
  );
};
