import React from 'react';
import { Box, ButtonV2, DatePickerInput, SelectV1, TextV2, Flex } from '@withjoy/joykit';
import { SearchBarRow, styles, SearchBarInput, SearchBarFormControl, SearchButtonWrapper } from './SearchBar.styles';
import { useSearchBarController } from './SearchBar.controller';
import { useServiceCenterState } from '@apps/serviceCenter/ServiceCenterState';
import Pagination from 'react-responsive-pagination';
import './pagination.css';

export const SearchBar = () => {
  const { totalPageCount } = useServiceCenterState();
  const { formik, pageSizeOptions, handlePageSizeSelect, handlePageNumberChange } = useSearchBarController();

  const onSearchClick = () => {
    if (formik.dirty) {
      formik.setFieldValue('pageNumber', 1);
      formik.submitForm();
    }
  };

  return (
    <Box paddingTop={6} paddingX={6}>
      <Box marginBottom={4}>
        <TextV2>Search by one or multiple fields. Text must be exact match.</TextV2>
        <TextV2>
          Subject search can be full subject line or FIRST word(s) of subject line, ie “Thanks for attending” or “Thanks” or “Thanks for”, but not “for” or “for attending”.
        </TextV2>
      </Box>
      <SearchBarRow __css={styles.SearchBarRow}>
        <SearchBarFormControl label="From Email" __css={styles.formControl} error={formik.errors.fromEmail}>
          <SearchBarInput {...formik.getFieldProps('fromEmail')} />
        </SearchBarFormControl>

        <SearchBarFormControl label="Reply to Email" __css={styles.formControl} error={formik.errors.replyToEmail}>
          <SearchBarInput {...formik.getFieldProps('replyToEmail')} />
        </SearchBarFormControl>

        <SearchBarFormControl label="to Email" __css={styles.formControl} error={formik.errors.toEmail}>
          <SearchBarInput {...formik.getFieldProps('toEmail')} />
        </SearchBarFormControl>

        <SearchBarFormControl label="Event Id" __css={styles.formControl} error={formik.errors.eventId}>
          <SearchBarInput {...formik.getFieldProps('eventId')} />
        </SearchBarFormControl>

        <SearchBarFormControl label="Subject" __css={styles.formControl} error={formik.errors.subject}>
          <SearchBarInput {...formik.getFieldProps('subject')} />
        </SearchBarFormControl>
      </SearchBarRow>
      <Box marginBottom={4}>
        <TextV2>Leave both dates blank to search all time.</TextV2>
        <TextV2>If only providing “From Date” results will show from that date to current day.</TextV2>
        <TextV2>If only providing “To Date” results will show email history up until that date.</TextV2>
      </Box>
      <SearchBarRow>
        <SearchBarFormControl label="From Date" __css={styles.formControl} error={formik.errors.startCreateDate}>
          <DatePickerInput
            mask={null}
            dateFormat="MM/dd/yyyy"
            {...formik.getFieldProps('startCreateDate')}
            onChange={date => {
              formik.setFieldValue('startCreateDate', date);
            }}
          />
        </SearchBarFormControl>
        <SearchBarFormControl label="To Date" __css={styles.formControl} error={formik.errors.endCreateDate}>
          <DatePickerInput
            mask={null}
            minDate={formik.getFieldProps('startCreateDate').value}
            dateFormat="MM/dd/yyyy"
            {...formik.getFieldProps('endCreateDate')}
            onChange={date => {
              formik.setFieldValue('endCreateDate', date);
            }}
          />
        </SearchBarFormControl>
      </SearchBarRow>
      <SearchButtonWrapper __css={styles.searchButtonWrapper}>
        <ButtonV2 variant="solid" intent="primary" onClick={onSearchClick} disabled={!formik.isValid}>
          Search
        </ButtonV2>
      </SearchButtonWrapper>
      <Flex flexDirection="column">
        <SearchBarRow>
          <SearchBarFormControl label="Page Size" __css={styles.formControl} error={formik.errors.pageSize}>
            <SelectV1 defaultValue={pageSizeOptions[1]} searchable={false} onChange={e => handlePageSizeSelect(Number(e?.value))} options={pageSizeOptions} />
          </SearchBarFormControl>
        </SearchBarRow>
        {!!totalPageCount && (
          <SearchBarRow justifyContent="left">
            <SearchBarFormControl label="Go to Page" __css={styles.formControl}>
              <Pagination
                className="service-center-pagination"
                pageItemClassName="service-center-pagination__page-item"
                pageLinkClassName="service-center-pagination__page-link"
                activeItemClassName="service-center-pagination__page-item--active"
                disabledItemClassName="service-center-pagination__page-item--disabled"
                srOnlyClassName="service-center-pagination__sr"
                current={formik.getFieldProps('pageNumber').value}
                total={totalPageCount}
                onPageChange={newPage => handlePageNumberChange(newPage)}
              />
            </SearchBarFormControl>
          </SearchBarRow>
        )}
      </Flex>
    </Box>
  );
};
