import React, { useCallback } from 'react';
import { DateFragment } from '@graphql/generated';
import { Box, LinkV2, Flex, TextV2 } from '@withjoy/joykit';
import { EventCountdown } from '../EventCountdown';
import { Link as RL } from '@react-router';
import { useLayout } from '../../layouts/LayoutProvider';
import { useResponsive } from '@shared/utils/hooks/useResponsive';
import { useTranslation } from 'react-i18next';
import { StyledRoot, StyledBoxViewDetails, StyledBoxText, StyledBoxArrow, StyledBoxArrowIcon, styles } from './EventLogisticBlock.styles';
export interface EventLogisticsBlockProps
  extends Readonly<{
    location?: Nullable<string>;
    eventHandle?: string;
    page?: Object;
    date?: Maybe<DateFragment & { timeString: string; dateString: string }>;
    shouldBreakDate?: boolean;
    hideEventCountdown: boolean;
    showViewDetailsButton?: boolean;
    nextPageSlug?: Maybe<string>;
    onViewDetailsButtonClicked?: () => void;
  }> {}

const _getEventDateHeader = (date: EventLogisticsBlockProps['date'], shouldBreak: boolean) => {
  let dateHeader = date ? date.dateString.replace(date.timeString, '').trim() : null;
  if (shouldBreak && dateHeader) {
    const [dayOfWeek, ...dates] = dateHeader.split(',');
    dateHeader = `${dayOfWeek}\n${dates.join(',')}`;
  }
  return dateHeader;
};

const EventLogisticsBlock: React.FC<EventLogisticsBlockProps> = ({
  children,
  page,
  eventHandle,
  location,
  date,
  shouldBreakDate = false,
  hideEventCountdown,
  showViewDetailsButton,
  nextPageSlug,
  onViewDetailsButtonClicked,
  ...restProps
}) => {
  const { t } = useTranslation('guestSiteWelcome');
  const { layout } = useLayout();
  const [isMobile] = useResponsive({ values: { xxs: true, xs: true, sm: false } });
  const isAloha = layout === 'aloha';
  const eventDateHeader = _getEventDateHeader(date, shouldBreakDate);

  const handleViewDetailsButton = useCallback(() => {
    onViewDetailsButtonClicked && onViewDetailsButtonClicked();
  }, [onViewDetailsButtonClicked]);

  if (!children && !location && !date) {
    return null;
  }

  return (
    // position relative should prevent the back sprite from being above the z of this box
    <StyledRoot __css={styles.root} {...restProps}>
      <Flex flexDirection={'column'} textAlign={'center'}>
        {eventDateHeader && (
          <TextV2 data-testid={'logistics-date'} typographyVariant={'display2'} whiteSpace={'pre-wrap'}>
            {eventDateHeader}
          </TextV2>
        )}
        {location && (
          <TextV2 data-testid={'logistics-location'} typographyVariant={'display1'} marginTop={7}>
            {location}
          </TextV2>
        )}
        {!hideEventCountdown && date && <EventCountdown data-testid={'logistics-countdown'} milliseconds={date.milliseconds} timezone={date.timezone} marginTop={6} />}
      </Flex>
      {children && <Box>{children}</Box>}
      {
        /* View Details + Arrow */
        isAloha && !isMobile && nextPageSlug && (
          <StyledBoxViewDetails show={!!showViewDetailsButton}>
            <LinkV2
              onClick={handleViewDetailsButton}
              as={RL}
              to={`/${eventHandle}/${nextPageSlug}`}
              color={'inherit'}
              _visited={{ color: 'inherit' }}
              _hover={{ color: 'inherit' }}
              _active={{ color: 'inherit' }}
              textAlign="center"
              textDecoration="none"
            >
              <StyledBoxText>{t('viewDetails')}</StyledBoxText>
              <StyledBoxArrow>
                <StyledBoxArrowIcon />
              </StyledBoxArrow>
            </LinkV2>
          </StyledBoxViewDetails>
        )
      }
    </StyledRoot>
  );
};

EventLogisticsBlock.displayName = 'EventLogisticsBlock';

export { EventLogisticsBlock };
