import { Flex, TextV2, ButtonV2, IconV2 } from '@withjoy/joykit';
import React from 'react';
import { ReactComponent as EditIcon } from '@assets/icons/edit-icon.svg';

interface ReceiptBlockProps {
  title: string;
  subTitle?: string;
  onEditButtonClick?: () => void;
  lines: Array<[label: string, value: string | undefined]>;
}

const ReceiptBlock: React.FC<ReceiptBlockProps> = ({ title, subTitle, onEditButtonClick, lines }) => {
  return (
    <Flex flexDirection="column">
      <Flex alignItems="center" justifyContent="space-between" marginBottom={subTitle ? 1 : 5}>
        <TextV2 typographyVariant="hed1">{title}</TextV2>
        {onEditButtonClick && (
          <ButtonV2 size="sm" variant="link" intent="neutral" fontSize="13px" onClick={onEditButtonClick}>
            <IconV2 size={11} marginRight="3px">
              <EditIcon width="11px" height="11px" />
            </IconV2>
            Edit
          </ButtonV2>
        )}
      </Flex>
      {subTitle && (
        <TextV2 typographyVariant="hed1" marginBottom={5}>
          {subTitle}
        </TextV2>
      )}
      <Flex flexDirection="column" rowGap={3}>
        {lines.map((line, index) => (
          <Flex key={index} justifyContent="space-between">
            <TextV2 typographyVariant="label2" color="mono12">
              {line[0]}
            </TextV2>
            <TextV2 typographyVariant="label2" color="mono12">
              {line[1]}
            </TextV2>
          </Flex>
        ))}
      </Flex>
    </Flex>
  );
};

export default ReceiptBlock;
