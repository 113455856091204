import { animationTransition } from '@shared/utils/animationTransition';
import { Box, styled, CSSObject, keyframes, css } from '@withjoy/joykit';
import { ThumbnailVariant } from './Skeleton.types';

// ============================
// Keyframes + mixins
// ============================

const shimmerKeyframes = keyframes`
0% {
  background-position: -200px 0;
}
100% {
  background-position: calc(200px + 100%) 0;
}
`;

export const shimmerMixin = css`
  background-color: #eee;
  background-image: linear-gradient(90deg, #eee, #f5f5f5, #eee);
  background-size: 200px 100%;
  background-repeat: no-repeat;
  animation: ${shimmerKeyframes} 1.3s ease-in-out infinite;
`;

const fadeKeyframes = keyframes`
  from {
    opacity: 0; 
  }
  to { 
    opacity: 1; 
  }
`;

const fadeMixin = css`
  animation: ${fadeKeyframes} 500ms cubic-bezier(0.5, 0, 0.5, 1);
`;

// ============================
// Styled Components
// ============================

// Skeleton

export const StyledSkeletonPlaceholderRoot = styled(Box)<{ $disableShimmerAnimation: boolean }>`
  box-shadow: none;
  background-clip: padding-box;
  cursor: default;
  pointer-events: ${__DEV__ ? 'all' : 'none'};
  user-select: none;
  ${props => (props.$disableShimmerAnimation ? undefined : shimmerMixin)};
  border-radius: 2px;
`;

export const StyledSkeletonContentRoot = styled(Box)<{ $shouldAnimateIn: boolean }>`
  ${props => (props.$shouldAnimateIn ? fadeMixin : undefined)};
`;

// Thumbnail

const thumbnailVariantStyles: ReadonlyRecord<ThumbnailVariant, CSSObject> = {
  circle: {
    borderRadius: '100%'
  },
  square: {
    borderRadius: '2px'
  }
};

export const StyledSkeletonThumbnailRoot = styled(StyledSkeletonPlaceholderRoot)<{ $variant: ThumbnailVariant }>`
  ${props => thumbnailVariantStyles[props.$variant]};
  transition: ${animationTransition('border-radius')};
`;

// Text

export const StyledSkeletonTextRoot = styled(Box)``;
export const StyledSkeletonTextRow = styled(Box)`
  border-radius: 2px;
  transition: ${animationTransition('width')};
`;
