import { VirtualScrollWrapper } from '@apps/admin/common/VirtualScroll';
import { applyScrollBarStyles } from '@shared/utils/styles';
import { pxToRem } from '@withjoy/joykit/theme';
import { Flex, styled } from '@withjoy/joykit';

export const SCROLL_BAR_WIDTH = 6;
// Used with Virtual List Items
export const VirtualScrollableArea = styled(VirtualScrollWrapper)<{ transparentScrollbar?: boolean }>`
  ${applyScrollBarStyles};
  ::-webkit-scrollbar {
    width: ${pxToRem(SCROLL_BAR_WIDTH)};
  }
  ::-webkit-scrollbar-thumb:vertical {
    border-right: ${SCROLL_BAR_WIDTH}px solid ${props => (props.transparentScrollbar ? 'transparent' : '#eaeaea')};
  }
`;
// Used without Virtual List Items
export const ScrollableArea = styled(Flex)<{ transparentScrollbar?: boolean }>`
  ${applyScrollBarStyles};
  ::-webkit-scrollbar {
    width: ${pxToRem(SCROLL_BAR_WIDTH)};
  }
  ::-webkit-scrollbar-thumb:vertical {
    border-right: ${SCROLL_BAR_WIDTH}px solid ${props => (props.transparentScrollbar ? 'transparent' : '#eaeaea')};
  }
`;

type HasFavoriteProperty = { isFavorite?: boolean | null };

export const sortByFavorite = <T extends HasFavoriteProperty>(list: T[]): T[] => {
  const isFavorite = list?.find(item => item.isFavorite);

  if (isFavorite) {
    const filtered = list?.filter(item => !item.isFavorite);
    return [{ ...isFavorite }, ...filtered];
  }
  return list;
};

type HasIdProperty = { id?: string };

export const sortByOrderList = <T extends HasIdProperty>(list: T[], orderList?: string[]): T[] => {
  let orderedList: typeof list = [];
  const listItemMap = new Map(list.map(item => [item.id, item]));
  if (orderList && orderList.length > 0) {
    orderList.forEach(orderId => {
      if (listItemMap.has(orderId)) {
        orderedList.push(listItemMap.get(orderId)!);
        listItemMap.delete(orderId);
      }
    });
    listItemMap.forEach(item => orderedList.push(item));
  } else {
    orderedList = [...list];
  }
  return orderedList?.filter(orderedListItem => orderedListItem !== undefined);
};
