import React from 'react';
import { Box, BoxProps, Flex, FlexProps, TextV2 } from '@withjoy/joykit';
import { pxToRem, styled } from '@withjoy/joykit/theme';

import { Home } from '@withjoy/joykit/icons';
import { useCardsRouterContext } from '@apps/card/Card.routes';
import { Link } from '@shared/core';

// ==============================
// Root element
// ==============================

interface GridProps {
  limitedHeight?: boolean;
}

const Grid: React.FC<GridProps> = ({ children, limitedHeight = false }) => {
  return (
    <Flex minHeight="100vh" maxHeight={limitedHeight ? { md: '100vh' } : undefined} flexDirection={{ _: 'column', md: 'row' }}>
      {children}
    </Flex>
  );
};

// ==============================
// Primary Column
// ==============================

const Main: React.FC<{ backgroundVariant?: 'tinted' }> = ({ children, backgroundVariant }) => {
  return (
    <Flex flexDirection="column" backgroundColor={backgroundVariant === 'tinted' ? 'mono1' : undefined} flex={{ md: 1 }}>
      {children}
    </Flex>
  );
};

const Header: React.FC<{
  title: string;
  borderVariant?: 'transparent';
  alignment?: BoxProps['justifyContent'];
  startIcon?: () => React.ReactNode;
  titleRedirectPath: string;
  onClickCallback?: () => void;
}> = ({ alignment, children, title, startIcon, borderVariant, titleRedirectPath, onClickCallback }) => {
  const { goToEventDashboard } = useCardsRouterContext();
  return (
    <Flex
      height={{ _: 10, md: pxToRem(60) }}
      backgroundColor={'white'}
      paddingX={{ _: 3, md: 6 }}
      paddingY={4}
      justifyContent={alignment || 'space-between'}
      alignItems="center"
      borderBottom="1px solid"
      borderBottomColor={borderVariant === 'transparent' ? 'transparent' : 'mono5'}
      onClick={onClickCallback}
    >
      <Flex alignItems="center" cursor="pointer" columnGap={5} paddingX={{ _: 3, md: 0 }} paddingY={{ _: 3, md: 0 }}>
        {startIcon?.() || <Home size={24} cursor="pointer" onClick={goToEventDashboard} />}
        <Box width="1px" height="36px" backgroundColor="mono3" />
        <TextV2 as={Link} to={titleRedirectPath} cursor="pointer" typographyVariant={{ _: 'hed2', md: 'hed5' }}>
          {title}
        </TextV2>
      </Flex>
      {children}
    </Flex>
  );
};

const Body: React.FC<Omit<BoxProps, '__css'>> = ({ children, ...restProps }) => {
  return (
    <Box overflowY="scroll" position="relative" padding={{ _: 6, md: 7 }} flex={1} {...restProps}>
      {children}
    </Box>
  );
};

// ==============================
// Secondary column
// ==============================

const SidePanelBlock: React.FC<{ fullBleed?: boolean } & BoxProps> = ({ backgroundColor, children, fullBleed, paddingY, rowGap = 0, ...boxProps }) => {
  return (
    <Flex flexDirection="column" backgroundColor={backgroundColor} paddingY={paddingY} paddingX={fullBleed ? undefined : 7} rowGap={rowGap} {...boxProps}>
      {children}
    </Flex>
  );
};

const SidePanelRoot = styled(Flex)``;

export interface SidePanelProps {
  title: string;
  children: React.ReactNode;
  rowGap?: number;
  withBlur?: boolean;
  onClickCallback?: () => void;
  paddingBottom?: number;
  overflowY?: 'hidden' | 'scroll';
}

const SidePanel = React.forwardRef(
  (
    { children, title, rowGap = 7, withBlur = false, onClickCallback, paddingBottom = 81, overflowY = 'scroll', ...props }: SidePanelProps & FlexProps,
    ref: React.Ref<HTMLDivElement>
  ) => {
    return (
      <SidePanelRoot
        ref={ref}
        flex={{ md: '0 0 375px' }}
        width={{ md: 375 }}
        flexDirection="column"
        rowGap={rowGap}
        paddingTop={7}
        right={{ md: 0 }}
        top={{ md: 0 }}
        borderLeft="1px solid"
        borderLeftColor="mono3"
        height={{ md: '100vh' }}
        paddingBottom={{ _: paddingBottom }}
        filter={withBlur ? 'blur(3px)' : 'none'}
        onClick={onClickCallback}
        position="relative"
        {...props}
        overflowY={{ md: overflowY }}
      >
        <TextV2 typographyVariant="hed6" fontWeight={600} paddingX={7}>
          {title}
        </TextV2>
        {children}
      </SidePanelRoot>
    );
  }
);

export const Layout = {
  Grid,

  Main,
  Body,
  Header,

  SidePanel,
  SidePanelBlock
};
