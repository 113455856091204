import { BOX_SHADOW_60_02 } from '@apps/card/boxShadow';
import { cardDieCutToShapeConfig } from '@apps/card/Card.utils';
import { Box } from '@withjoy/joykit';
import React from 'react';
import { CardDieCut } from '../CardCustomizer.types';
import { PPI } from '../steps/CardDesign/config';
import { PageScaler } from './PageScaler';

const CARD_WIDTH = 5.25 * PPI;
const CARD_HEIGHT = 7.25 * PPI;
const CUT_MARGIN = 0.125 * PPI;

interface CardPreviewProps {
  image: string;
  shape: CardDieCut;
}

export const CardPreview: React.FC<CardPreviewProps> = ({ image, shape }) => {
  const { borderRadius, clipPath } = cardDieCutToShapeConfig(shape);

  return (
    <PageScaler key={image}>
      <Box width={CARD_WIDTH - CUT_MARGIN * 2} height={CARD_HEIGHT - CUT_MARGIN * 2} borderRadius={borderRadius} boxShadow={BOX_SHADOW_60_02}>
        <Box position="relative" height="100%" borderRadius={borderRadius} clipPath={clipPath}>
          <Box as="img" position="absolute" top={-CUT_MARGIN} left={-CUT_MARGIN} width={CARD_WIDTH} height={CARD_HEIGHT} src={image} />
        </Box>
      </Box>
    </PageScaler>
  );
};
