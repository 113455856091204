import React from 'react';
import { EventPageType } from '@graphql/generated';

import { CoupleStory } from '../../../../widgets/CoupleStory';
import { FaqList } from '../../../../widgets/FaqList';
import { ScheduleList } from '../../../../widgets/ScheduleList';
import { TravelList } from '../../../../widgets/TravelList';
import { WeddingPartyList } from '../../../../widgets/WeddingPartyList';
import { TidbitsList } from '../../../../widgets/TidbitsList';
import { LayoutAlohaProps } from '../../../LayoutAloha';
import { Box, Flex, ColumnV2Props } from '@withjoy/joykit';
import { EventLogisticsBlock } from '../../../../components/EventLogisticsBlock';
import { RegistryList } from '@apps/guest/packages/layout-engine/widgets/RegistryList';
import { CONTENT_BLOCK_MAX_WIDTH, CONTENT_BLOCK_PADDING__X } from '../../LayoutAloha.theme';
import { CtaButtons } from '@apps/guest/packages/layout-engine/components/CtaButtons/CtaButtons';
import Apps from '@apps/guest/packages/layout-engine/widgets/Apps';
import Custom from '@apps/guest/packages/layout-engine/widgets/Custom';

export type PreparedPages =
  | EventPageType.app
  | EventPageType.registry
  | EventPageType.travel
  | EventPageType.schedule
  | EventPageType.faq
  | EventPageType.vip
  | EventPageType.story
  | EventPageType.tidbits
  | EventPageType.welcome
  | EventPageType.custom;

export const pageColumnProps: Readonly<Record<PreparedPages, ColumnV2Props>> = {
  [EventPageType.app]: { span: [10], offset: [1] },
  [EventPageType.faq]: { span: [10, 6], offset: [1, 3] },
  [EventPageType.registry]: { span: [10], offset: [1] },
  [EventPageType.schedule]: { span: [12, 10, 8], offset: [0, 1, 2] },
  [EventPageType.story]: { span: [10, 8], offset: [1, 2] },
  [EventPageType.tidbits]: { span: [10, 8], offset: [1, 2] },
  [EventPageType.travel]: { span: [10], offset: [1] },
  [EventPageType.vip]: { span: [10, 10], offset: [1, 1] },
  [EventPageType.welcome]: {},
  [EventPageType.custom]: {}
};

export const pageWidgets: Readonly<Record<PreparedPages, React.ForwardRefExoticComponent<LayoutAlohaProps & React.RefAttributes<HTMLDivElement>>>> = {
  [EventPageType.custom]: React.forwardRef<HTMLDivElement, LayoutAlohaProps>((props, ref) => (
    <Flex ref={ref} data-testid={'page-custom'} alignItems="center" justifyContent={'center'} maxWidth={'500px'} width={'100%'}>
      <Custom pageId={props.pageId || ''} pageSlug={props.pageSlug || ''} eventId={props.event.id} pageTitle={props.pageTitle} eventHandle={props.eventHandle} />
    </Flex>
  )),
  [EventPageType.app]: React.forwardRef<HTMLDivElement, LayoutAlohaProps>((props, ref) => (
    <Box
      ref={ref}
      data-testid={'page-apps'}
      display={'flex'}
      alignItems="center"
      margin={'0 auto'}
      justifyContent={'center'}
      maxWidth={CONTENT_BLOCK_MAX_WIDTH}
      paddingX={CONTENT_BLOCK_PADDING__X}
      paddingY={[10]}
    >
      <Apps data-testid={'page-app'} />
    </Box>
  )),
  [EventPageType.faq]: React.forwardRef<HTMLDivElement, LayoutAlohaProps>((props, ref) => (
    <FaqList
      ref={ref}
      data-testid={'page-faq'}
      eventHandle={props.eventHandle}
      margin={'0 auto'}
      maxWidth={CONTENT_BLOCK_MAX_WIDTH}
      paddingX={CONTENT_BLOCK_PADDING__X}
      paddingY={[10]}
    />
  )),
  [EventPageType.registry]: React.forwardRef<HTMLDivElement, LayoutAlohaProps>((props, ref) => (
    <RegistryList
      ref={ref}
      eventId={props.event.id}
      eventHandle={props.eventHandle}
      margin={'0 auto'}
      paddingX={CONTENT_BLOCK_PADDING__X}
      paddingTop={{
        _: 7,
        lg: 56
      }}
    />
  )),
  [EventPageType.schedule]: React.forwardRef<HTMLDivElement, LayoutAlohaProps>((props, ref) => (
    <ScheduleList
      ref={ref}
      data-testid={'page-schedule'}
      eventHandle={props.eventHandle}
      variant={'stack'}
      eventId={props.event.id}
      margin={'0 auto'}
      maxWidth={CONTENT_BLOCK_MAX_WIDTH}
      paddingX={CONTENT_BLOCK_PADDING__X}
      paddingY={[10]}
    />
  )),
  [EventPageType.story]: React.forwardRef<HTMLDivElement, LayoutAlohaProps>((props, ref) => (
    <CoupleStory
      ref={ref}
      data-testid={'page-story'}
      stackByDefault={true}
      margin={'0 auto'}
      maxWidth={CONTENT_BLOCK_MAX_WIDTH}
      paddingX={CONTENT_BLOCK_PADDING__X}
      paddingY={[10]}
      eventHandle={props.eventHandle}
    />
  )),
  [EventPageType.tidbits]: React.forwardRef<HTMLDivElement, LayoutAlohaProps>((props, ref) => (
    <TidbitsList
      ref={ref}
      data-testid={'page-tidbits'}
      eventHandle={props.eventHandle}
      stackByDefault={true}
      margin={'0 auto'}
      maxWidth={CONTENT_BLOCK_MAX_WIDTH}
      paddingX={CONTENT_BLOCK_PADDING__X}
      paddingY={[10]}
    />
  )),
  [EventPageType.travel]: React.forwardRef<HTMLDivElement, LayoutAlohaProps>((props, ref) => (
    <TravelList
      ref={ref}
      data-testid={'page-travel'}
      eventId={props.event.id}
      eventHandle={props.eventHandle}
      useMiniNav={false}
      condensed={true}
      margin={'0 auto'}
      maxWidth={CONTENT_BLOCK_MAX_WIDTH}
      paddingX={CONTENT_BLOCK_PADDING__X}
      paddingY={[10]}
    />
  )),
  [EventPageType.vip]: React.forwardRef<HTMLDivElement, LayoutAlohaProps>((props, ref) => (
    <WeddingPartyList
      eventId={props.event.id}
      ref={ref}
      data-testid={'page-vip'}
      graphicAccent={undefined}
      columnCount={6}
      margin={'0 auto'}
      maxWidth={CONTENT_BLOCK_MAX_WIDTH}
      paddingX={CONTENT_BLOCK_PADDING__X}
      paddingY={[10]}
    />
  )),
  [EventPageType.welcome]: React.forwardRef<HTMLDivElement, LayoutAlohaProps>(({ event, ...restProps }, ref) => (
    <Box
      ref={ref}
      data-testid={'page-welcome'}
      height={'100vh'}
      display={'flex'}
      alignItems="center"
      maxWidth={CONTENT_BLOCK_MAX_WIDTH}
      paddingX={CONTENT_BLOCK_PADDING__X}
      {...restProps}
    >
      <EventLogisticsBlock showViewDetailsButton={false} location={event.info.location} date={event.info.finalizedDate} hideEventCountdown={event.settings.hideEventCountdown}>
        <CtaButtons />
      </EventLogisticsBlock>
    </Box>
  ))
};
