import { CardPaperType } from '../../CardCustomizer.types';

export const MEDIA_SERVICE = 'https://withjoy.com/media';
export const PPI = 72;

interface CardTypeInfo {
  label: string;
  description: string;
  weight: string;
  thickness?: string;
  sku: string;
  skuType: string;
  imageUrl: string;
}

export const CARD_TYPES: Record<CardPaperType, CardTypeInfo> = {
  [CardPaperType.signature]: {
    label: 'Signature',
    description: 'Our luxurious signature card stock has a velvety cotton texture and elegant eggshell finish.',
    weight: '120lb. / 324gsm',
    sku: 'WJYA7SFW',
    skuType: 'FlatNoteCard',
    imageUrl: 'https://withjoy.com/media/paper-type/signature.jpg'
  },
  [CardPaperType.pearlescent]: {
    label: 'Pearlescent',
    description: 'Add an elegant iridescence to printed cards and a soft glow to photographs with Pearlescent paper.',
    weight: '110lb. / 300gsm',
    sku: 'WJYA7WP',
    skuType: 'A7 Flat Card, White Pearl Shimmer 2S',
    imageUrl: 'https://withjoy.com/media/paper-type/pearlescent.jpg'
  },
  [CardPaperType.doubleThick]: {
    label: 'Double Thick',
    description: 'Twice as thick as our signature paper, with the same eggshell finish and velvety cotton texture.',
    weight: '240lb. / 648gsm',
    thickness: '30pt',
    sku: 'WJYA7SFWDT',
    skuType: 'A7 Flat Card, 120# Superfine White Double Thick, 2S',
    imageUrl: 'https://withjoy.com/media/paper-type/double.jpg'
  },
  [CardPaperType.tripleThick]: {
    label: 'Triple Thick',
    description: 'Extra thick and luxurious, with the same eggshell finish and cottony texture as our signature paper.',
    weight: '360lb. / 972gsm',
    thickness: '60pt',
    sku: 'WJYA7SFWTT',
    skuType: 'A7 Flat Card, 120# Superfine White Triple Thick, 2S',
    imageUrl: 'https://withjoy.com/media/paper-type/triple.jpg'
  }
};
