import { milesToMeters } from '@shared/utils/milesToMeters';

export const centerAndZoom = (center: google.maps.LatLng | google.maps.LatLngLiteral, miles: number, map: google.maps.Map) => {
  // Fit map to a specific mile radius around a center point
  const circle = new google.maps.Circle({
    center,
    radius: milesToMeters(miles),
    fillOpacity: 0,
    fillColor: 'white',
    strokeWeight: 0,
    map
  });
  if (circle.getBounds()) {
    map.fitBounds(circle.getBounds()!);
    // Remove circle after map is set to proper bounds
    circle.setMap(null);
  }
};

export const getCalculatedCenterAndZoom = (markers: google.maps.LatLngLiteral[]): { center: google.maps.LatLngLiteral; bounds: google.maps.LatLngBounds } => {
  const minLat = Math.min(...markers.map(marker => marker.lat));
  const maxLat = Math.max(...markers.map(marker => marker.lat));
  const minLng = Math.min(...markers.map(marker => marker.lng));
  const maxLng = Math.max(...markers.map(marker => marker.lng));

  // Calculate the center of the bounding box
  const center: google.maps.LatLngLiteral = {
    lat: (minLat + maxLat) / 2,
    lng: (minLng + maxLng) / 2
  };

  // Calculate the bounds
  const bounds = new google.maps.LatLngBounds(new google.maps.LatLng(minLat, minLng), new google.maps.LatLng(maxLat, maxLng));

  return { center, bounds };
};

export const reduceBounds = (bounds: google.maps.LatLngBounds, reductionFactor: number) => {
  const northEast = bounds.getNorthEast();
  const southWest = bounds.getSouthWest();

  const latReduction = (northEast.lat() - southWest.lat()) * reductionFactor;
  const lngReduction = (northEast.lng() - southWest.lng()) * reductionFactor;

  const reducedNorthEast = new google.maps.LatLng(northEast.lat() - latReduction, northEast.lng() - lngReduction);
  const reducedSouthWest = new google.maps.LatLng(southWest.lat() + latReduction, southWest.lng() + lngReduction);

  const reducedBounds = new google.maps.LatLngBounds(reducedSouthWest, reducedNorthEast);
  return reducedBounds;
};
