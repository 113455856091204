import { StyleSystemProps } from '@withjoy/joykit';

const actionContainer: StyleSystemProps = {
  width: 130,
  height: 130,
  padding: 6,
  borderRadius: 3,
  border: 'solid 1px',
  borderColor: 'linkText',
  _hover: {
    borderColor: 'linkHover'
  },
  _active: {
    borderColor: 'linkActive'
  },
  _notLast: {
    marginRight: 2
  }
};

export const styles = {
  actionContainer
};
