import { ModalProps, styled } from '@withjoy/joykit';

export const BookingAssistantItemWrapper = styled.li`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 21.14px 61.5px;
  border: 1px solid ${props => props.theme.colors.gray5};
  width: 244px;
  border-radius: 4px;
  &:hover {
    background-color: ${props => props.theme.colors.gray1};
    border-color: ${props => props.theme.colors.gray6};
  }
  &:active {
    background-color: ${props => props.theme.colors.gray2};
    border-color: ${props => props.theme.colors.gray7};
  }
`;

export const dialogOverridesDesktop: NonNullable<ModalProps['overrides']> = {
  Body: {
    props: {
      borderTop: '0px solid !important'
    }
  },
  Header: {
    props: {
      _first: {
        paddingTop: '40px',
        paddingBottom: '0px'
      }
    }
  }
};

export const dialogOverridesMobile: NonNullable<ModalProps['overrides']> = {
  Body: {
    props: {
      borderTop: '0px solid !important'
    }
  },
  Header: {
    props: {
      _first: {
        paddingTop: '40px',
        paddingBottom: '0px'
      }
    }
  }
};
