import { useHistory } from '@shared/core';
import { withWindow } from '@shared/utils/withWindow';
import { Flex } from '@withjoy/joykit';
import React, { useEffect, useState } from 'react';
import { useMount } from '@shared/utils/hooks/useMount';
import PageNotFound from '@apps/pageNotFound/PageNotFound';
import { useHotelBookingGuestSiteTelemetry } from '../../HotelBooking.telemetry';
import { getValidBookingLink } from '../../HotelBooking.utils';
import { useFeatureValue } from '@shared/core/featureFlags';
import { useQueryParams } from '@shared/utils/hooks/useQueryParams';
import { getConfig } from '@config';
import { generateGoogleUrl } from '@shared/utils/googleUrl';
import { JoyLogoLoader } from '@shared/components/JoyLogoLoader';

const ZH_BASE_URL = {
  DEV: `https://joylife-demo.prod.zentrumhub.com/hotels/v2/`,
  PROD: `https://joylife.prod.zentrumhub.com/hotels/v2/`,
  PROD_JOY_URL: `https://hotels.withjoy.com/hotels/v2/`
};

export const HotelBookingPartner: React.FC<{ hotelId: string }> = ({ hotelId }) => {
  const history = useHistory();

  const [showNotFound, setShowNotFound] = useState<boolean>(false);

  const telemetry = useHotelBookingGuestSiteTelemetry();

  const zentrumHubEnabled = useFeatureValue('zentrumHubEnabled').value === 'on';
  const withjoyZhUrlEnabled = useFeatureValue('zentrumHubWithjoyUrl').value === 'on';
  const { value, loading } = useFeatureValue('partnerZentrumHubHotelsExperiment', {
    skip: !zentrumHubEnabled
  });

  const queryParams = useQueryParams();

  const isDev = getConfig().environment === 'dev';

  useMount(() => {
    telemetry.hotelbooking.enter();
  });

  useEffect(() => {
    if (loading || !hotelId || value === undefined) return;

    withWindow(() => {
      /**
       * Redirect to GoogleUrl
       */
      if (value !== 'treatment') {
        const fallbackGoogleId = queryParams.gplid;
        const googleUrl = generateGoogleUrl(fallbackGoogleId as string);
        const validHotelLink = getValidBookingLink(googleUrl);
        telemetry.guestPartnerHotelBookingRedirect(googleUrl, validHotelLink?.hostname || '', true);
        window.location.replace(googleUrl);
        return;
      }

      /**
       * Redirect to ZH Url
       */
      const zhUrl = (isDev ? ZH_BASE_URL.DEV : withjoyZhUrlEnabled ? ZH_BASE_URL.PROD_JOY_URL : ZH_BASE_URL.PROD) + hotelId;
      const validBookingLink = getValidBookingLink(zhUrl);
      if (validBookingLink) {
        telemetry.guestPartnerHotelBookingRedirect(zhUrl, validBookingLink.hostname, true);
        window.location.replace(zhUrl);
      } else {
        telemetry.guestPartnerHotelBookingRedirect(zhUrl, zhUrl, false);
        setShowNotFound(true);
      }
    });
  }, [history, hotelId, isDev, loading, queryParams.gplid, telemetry, value, withjoyZhUrlEnabled]);

  if (showNotFound) {
    return <PageNotFound />;
  }

  return (
    <Flex justifyContent="center" alignItems="center" height="100vh" width="100vw">
      <JoyLogoLoader loaderKey="guest-hotel-booking-partner" />
    </Flex>
  );
};
