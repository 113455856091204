import React from 'react';
import SearchBar from './components/SearchBar';
import { ResultsTable } from '@apps/serviceCenter/routes/Messages/components/ResultsTable';
import { TableActions } from '@apps/serviceCenter/routes/Messages/components/TableActions';
import { Box } from '@withjoy/joykit';

export const Messages = () => {
  return (
    <Box width="100%" maxWidth="1720px">
      <SearchBar />
      <TableActions />
      <ResultsTable />
    </Box>
  );
};
