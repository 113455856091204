import { EventPageType, PhotoXPosition, PhotoYPosition, useSetPhotoAlignmentMutation } from '@graphql/generated';
import globalWindow from '@shared/core/globals';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { AuxFrameContext } from '../../AuxFrame';
import { useMediaQuery } from '@withjoy/joykit/utils';
import { PhotoAlignmentX, PhotoAlignmentY } from '@apps/guest/packages/layout-engine/layouts/LayoutAloha/components/AuxFrame/AuxFrame.types';

enum Orientation {
  LANDSCAPE,
  PORTRAIT,
  SQUARE
}

const SIZE_SETTING: Record<Orientation, { container: [number, number]; [key: string]: [number, number] }> = {
  [Orientation.LANDSCAPE]: {
    container: [504, 416],
    '3:2': [504, 336], // [width, height]
    '4:3': [504, 378],
    '5:4': [504, 403]
  },
  [Orientation.PORTRAIT]: {
    container: [416, 504],
    '2:3': [336, 504],
    '3:4': [378, 504],
    '4:5': [403, 504]
  },
  [Orientation.SQUARE]: {
    container: [440, 440],
    '1:1': [400, 400],
    '11:10': [440, 400],
    '10:11': [400, 440]
  }
};

const closest = (ratio: number, ratiosArray: number[]) => {
  let curr = ratiosArray[0];
  let diff = Math.abs(ratio - curr);
  for (let val = 0; val < ratiosArray.length; val++) {
    const newdiff = Math.abs(ratio - ratiosArray[val]);
    if (newdiff < diff) {
      diff = newdiff;
      curr = ratiosArray[val];
    }
  }
  return curr;
};

const ratiosArray = ['1:1', '2:3', '3:2', '3:4', '4:3', '4:5', '5:4', '11:10', '10:11'];
const ratioFloatsArray = ratiosArray.map(item => {
  const temp = item.split(':');
  return parseFloat(temp[0]) / parseFloat(temp[1]);
});

const calculateAspectRatio = (width: number, height: number) => {
  const currentRatioFloat = width / height;
  const matchedRatioFloat = closest(currentRatioFloat, ratioFloatsArray);
  const matchedRatio = ratiosArray[ratioFloatsArray.indexOf(matchedRatioFloat)];
  let orientation;
  if (['1:1', '11:10', '10:11'].includes(matchedRatio)) {
    orientation = Orientation.SQUARE;
  } else if (matchedRatioFloat > 1) {
    orientation = Orientation.LANDSCAPE;
  } else {
    orientation = Orientation.PORTRAIT;
  }

  const size = SIZE_SETTING[orientation];
  const imageSize = [...size[matchedRatio]];
  const containerSize = [...size.container];
  const windowWidth = globalWindow.innerWidth;
  const windowHeight = globalWindow.innerHeight;
  const IMG_PADDINNG_X = 48;
  const DESKTOP_HEIGHT_EXCLUDE_IMAGE = 332;
  if (windowWidth && windowWidth <= 768) {
    if (imageSize[0] + IMG_PADDINNG_X > windowWidth) {
      imageSize[0] = windowWidth - IMG_PADDINNG_X;
      imageSize[1] = imageSize[0] / matchedRatioFloat;
    }
  } else if (windowWidth && windowWidth > 768 && windowHeight && imageSize[1] + DESKTOP_HEIGHT_EXCLUDE_IMAGE > windowHeight) {
    imageSize[1] = windowHeight - DESKTOP_HEIGHT_EXCLUDE_IMAGE;
    imageSize[0] = imageSize[1] * matchedRatioFloat;
    containerSize[0] = imageSize[0];
    containerSize[1] = imageSize[1];
  }
  return {
    orientation,
    aspectRatio: matchedRatio,
    size: {
      container: containerSize,
      image: imageSize
    }
  };
};

export const useFocusPointController = ({
  isOpen,
  onFocusPointChange,
  onFocusPointChangeError
}: {
  isOpen: boolean;
  onFocusPointChange: (lastPoint: [PhotoXPosition, PhotoYPosition], newPoint: [PhotoXPosition, PhotoYPosition]) => void;
  onFocusPointChangeError: (error: unknown) => void;
}) => {
  const isMobileScreen = useMediaQuery(theme => theme.mediaQueries.between(0, { breakpointAlias: 'sm2' }));
  const isTabletScreen = useMediaQuery(theme => theme.mediaQueries.between({ breakpointAlias: 'sm2' }, { breakpointAlias: 'md' }));
  const isSmallDesktopScreen = useMediaQuery(theme => theme.mediaQueries.between({ breakpointAlias: 'md' }, { breakpointAlias: 'md3' }));

  const { eventId, page, pageId, photo, previewFocusPoint, reflectPhotoChange, pages } = useContext(AuxFrameContext);
  const [setPhotoAlignment] = useSetPhotoAlignmentMutation({});

  /* show top left/top right border radius when dialog height less than device height */
  const [showTopRadius, setShowTopRadius] = useState(false);
  const dialogRef = useRef<HTMLDivElement>(null);

  const [aspectRatio, setAspectRatio] = useState({
    orientation: Orientation.SQUARE,
    aspectRatio: '1:1',
    size: {
      container: [400, 400],
      image: [400, 400]
    }
  });

  useEffect(() => {
    const checkModalPosition = () => {
      setTimeout(() => {
        if (dialogRef.current && isMobileScreen) {
          const rect = dialogRef.current.getBoundingClientRect();
          setShowTopRadius(rect.top > 5);
        }
        setAspectRatio(calculateAspectRatio(photo?.width || 1, photo?.height || 1));
      }, 250);
    };
    window.addEventListener('resize', checkModalPosition);
    checkModalPosition();
    setAspectRatio(calculateAspectRatio(photo?.width || 1, photo?.height || 1));
    return () => window.removeEventListener('resize', checkModalPosition);
  }, [isOpen, isMobileScreen, photo?.width, photo?.height]);

  const alignX = (photo?.layout.alignX as PhotoAlignmentX) || 'center';
  const alignY = (photo?.layout.alignY as PhotoAlignmentY) || 'center';

  const [focusPoint, setFocusPoint] = useState<[PhotoXPosition, PhotoYPosition]>([PhotoXPosition[alignX], PhotoYPosition[alignY]]);

  useEffect(() => {
    setFocusPoint([PhotoXPosition[alignX], PhotoYPosition[alignY]]);
  }, [alignX, alignY]);

  const prevFocusPoint: [PhotoXPosition, PhotoYPosition] = useMemo(() => {
    const pageInfo = pages.find(p => p.type === page);
    const welcomePageInfo = pages.find(p => p.type === EventPageType.welcome);
    // welcome page photo alignament as fallback, if welcome page photo is not available then use center alignment
    return [
      (pageInfo?.photo?.layout.alignX as PhotoXPosition) || welcomePageInfo?.photo?.layout.alignX || PhotoXPosition.center,
      (pageInfo?.photo?.layout.alignY as PhotoYPosition) || welcomePageInfo?.photo?.layout.alignY || PhotoYPosition.center
    ];
  }, [pages, page]);

  const resetFocusPoint = async () => {
    setFocusPoint([prevFocusPoint[0], prevFocusPoint[1]]);
    previewFocusPoint(prevFocusPoint[0], prevFocusPoint[1]);
  };

  const changeFocusPoint = (x: PhotoXPosition, y: PhotoYPosition) => () => {
    setFocusPoint([x, y]);
    previewFocusPoint(x, y);
  };

  const saveFocusPoint = async () => {
    const payload = {
      page: isMobileScreen ? EventPageType.welcome : page,
      isMobile: isMobileScreen,
      alignment: {
        x: focusPoint[0],
        y: focusPoint[1]
      }
    };
    setPhotoAlignment({
      variables: {
        eventId,
        payload
      }
    })
      .then(() => onFocusPointChange(prevFocusPoint, focusPoint))
      .catch(err => onFocusPointChangeError(err));
  };

  const previewPosition = (x: PhotoXPosition, y: PhotoYPosition) => previewFocusPoint(x, y);

  return {
    aspectRatio,
    changeFocusPoint,
    dialogRef,
    focusPoint,
    isMobileScreen,
    isTabletScreen,
    isSmallDesktopScreen,
    page,
    pageId,
    photo,
    previewPosition,
    saveFocusPoint,
    resetFocusPoint,
    showTopRadius,
    eventId,
    reflectPhotoChange
  };
};
