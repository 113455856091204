import { css } from '@withjoy/joykit';

export const applyScrollBarStyles = css`
  ::-webkit-scrollbar {
    width: 12px;
    /* To avoid Safari;s momentum based scrolling, set this to "none"   */
    -webkit-appearance: none;
  }
  ::-webkit-scrollbar-track:vertical {
    border-right: 6px solid rgba(0, 0, 0, 0);
  }
  ::-webkit-scrollbar-thumb:vertical {
    border-right: 6px solid #eaeaea;
  }

  ::-webkit-scrollbar-track:horizontal {
    border-bottom: 6px solid rgba(0, 0, 0, 0);
  }
  ::-webkit-scrollbar-thumb:horizontal {
    border-bottom: 6px solid #eaeaea;
  }
`;
