import React from 'react';

import { ColumnsV2, ColumnsV2Props } from '@withjoy/joykit';
import { animationTransition } from '@shared/utils/animationTransition';
import { StyledBoxBrannan } from './Frames.styles';
import { isInIframe } from '@shared/utils/isInIframe';
import { useMediaQuery } from '@withjoy/joykit/utils';
import { useGuestSiteState } from '@apps/guest/routes/GuestSite/GuestSite.state.provider';
import { pxToRem } from '@withjoy/joykit/theme';
import { NO_LEAD_BANNER_HEIGHT, NO_LEAD_BANNER_HEIGHT_MOBILE } from '@apps/guest/packages/layout-engine/widgets/Custom/Custom.constants';

const FrameOuter: React.FC<HtmlProps<HTMLElement> & { applicatorProp: object; loading: boolean; customBackgroundColor?: string; isCustomPage?: boolean }> = ({
  applicatorProp,
  children,
  loading,
  customBackgroundColor,
  isCustomPage,
  ...restProps
}) => {
  const isPreviewing = isInIframe();
  const isMobile = useMediaQuery(theme => theme.mediaQueries.between(0, { breakpointAlias: 'sm2' }));
  const { enabledAdminGuestSiteBannerDialog } = useGuestSiteState();
  return (
    <StyledBoxBrannan
      {...(enabledAdminGuestSiteBannerDialog ? { marginTop: [pxToRem(NO_LEAD_BANNER_HEIGHT_MOBILE), pxToRem(NO_LEAD_BANNER_HEIGHT)] } : {})}
      loading={loading}
      {...applicatorProp}
      overflow={isCustomPage && !isMobile ? 'initial' : 'hidden'}
      transition={animationTransition('background', 'background-color')}
      data-testid={'outer-frame'}
      {...restProps}
      position={'relative'}
      isPreviewing={isPreviewing}
      customBackgroundColor={customBackgroundColor}
    >
      {children}
    </StyledBoxBrannan>
  );
};

const FrameInner: React.FC<HtmlProps<HTMLElement> & ColumnsV2Props> = ({ children, ...restProps }) => {
  return (
    <ColumnsV2 fluid={true} maxWidth={[null, null, 1024]} {...restProps}>
      <ColumnsV2.Column data-testid={'inner-frame'}>{children}</ColumnsV2.Column>
    </ColumnsV2>
  );
};

export { FrameOuter, FrameInner };
