import React, { useCallback, useEffect } from 'react';
import { Box, ButtonV2, Flex, InputV2, Radio } from '@withjoy/joykit';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useGetEventByIdServiceCenterLazyQuery, useGetEventByNameServiceCenterLazyQuery } from '@graphql/generated';
import { useResponsive } from '@shared/utils/hooks/useResponsive';

enum FilterBy {
  EVENT_NAME = 'EVENT_NAME',
  EVENT_ID = 'EVENT_ID'
}

interface FormikEventsForm {
  eventKey: string;
  filterBy: FilterBy;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const SearchEventBy: React.FC<{ setLoadingEvent: React.Dispatch<boolean>; setEventFound: React.Dispatch<any> }> = ({ setLoadingEvent, setEventFound }) => {
  const [isMobile] = useResponsive({ values: { mobile: true, tablet: false } });

  const formik = useFormik<FormikEventsForm>({
    initialValues: {
      eventKey: '',
      filterBy: FilterBy.EVENT_NAME
    },
    validationSchema: Yup.object<FormikEventsForm>({
      eventKey: Yup.string().required(),
      filterBy: Yup.mixed().oneOf([FilterBy.EVENT_NAME, FilterBy.EVENT_ID])
    }),
    onSubmit: (values, actions) => {
      if (values.filterBy === FilterBy.EVENT_NAME) {
        findEventByName({ variables: { handle: values.eventKey } });
      } else {
        findEventById({ variables: { id: values.eventKey } });
      }
    },
    validateOnMount: true
  });

  const [findEventById, { loading: loadingEventById }] = useGetEventByIdServiceCenterLazyQuery({
    batchMode: 'fast',
    fetchPolicy: 'no-cache',
    variables: { id: formik.values.eventKey },
    onCompleted: async data => {
      const event = data?.eventById;
      setEventFound(event);
    }
  });

  const [findEventByName, { loading: loadingEventByName }] = useGetEventByNameServiceCenterLazyQuery({
    batchMode: 'fast',
    fetchPolicy: 'no-cache',
    variables: { handle: formik.values.eventKey },
    onCompleted: async data => {
      const event = data?.eventByName;
      setEventFound(event);
    }
  });

  useEffect(() => {
    setLoadingEvent(loadingEventById || loadingEventByName);
  }, [loadingEventById, loadingEventByName, setLoadingEvent]);

  const onSearchClick = useCallback(() => {
    setEventFound(null);
    formik.submitForm();
  }, [formik, setEventFound]);

  return (
    <Box width="100%" maxWidth="1720px" margin={40}>
      <Flex flexDirection={'row'} columnGap={4}>
        <Box width={300}>
          <InputV2 placeholder="Event Name or Event ID" {...formik.getFieldProps('eventKey')} />
        </Box>
        <ButtonV2 variant="solid" intent="primary" onClick={onSearchClick} disabled={!formik.isValid}>
          Search
        </ButtonV2>
      </Flex>
      <Flex paddingRight={4} paddingY={4} columnGap={10} flexDirection={isMobile ? 'column' : 'row'}>
        <Radio
          onChange={formik.handleChange}
          id="filterByName"
          checked={formik.values.filterBy === FilterBy.EVENT_NAME}
          tabIndex={0}
          value={FilterBy.EVENT_NAME}
          label="Search by Event Name"
          name="filterBy"
        />
        <Radio
          onChange={formik.handleChange}
          id="filterById"
          checked={formik.values.filterBy === FilterBy.EVENT_ID}
          tabIndex={0}
          value={FilterBy.EVENT_ID}
          label="Search by EventID"
          name="filterBy"
        />
      </Flex>
    </Box>
  );
};
