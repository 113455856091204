import { styled, StyleSystemProps } from '@withjoy/joykit';

export const StyledTableWrapper = styled.div`
  padding: 1rem;
  text-align: left;
  font-size: 14px;

  table {
    border-spacing: 0;
    border: 1px solid black;

    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    th,
    td {
      margin: 0;
      padding: 0.5rem 2rem 0.5rem 0.5rem;
      border-bottom: 1px solid black;
      border-right: 1px solid black;

      :last-child {
        border-right: 0;
      }
    }
  }
`;

const StatusDotStyles: StyleSystemProps = {
  borderRadius: 50,
  width: '10px',
  height: '10px',
  marginRight: 3,
  display: 'inline-block'
};

const NotResultsWrapperStyles: StyleSystemProps = {
  justifyContent: 'center',
  marginTop: 8
};

export const styles = {
  statusDot: StatusDotStyles,
  noResultsWrapper: NotResultsWrapperStyles
};
