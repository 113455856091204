import { useCallback } from 'react';

import { useHistory } from '@shared/core';
import { EventPageFragment } from '@graphql/generated';
import { getSlugFromPathname } from '@apps/guest/packages/layout-engine/common/utils/useGuestSitePageChanged';

export const usePageInHistory = () => {
  const history = useHistory();

  const replacePageInBrowserHistory = useCallback(
    (page: EventPageFragment | null) => {
      if (!page) {
        return;
      }

      const pageSlug = page?.pageSlug;
      const slugFromPathname = getSlugFromPathname(history.location.pathname);

      if (slugFromPathname !== pageSlug) {
        const pathnameParts = history.location.pathname.split('/');
        pathnameParts[2] = pageSlug;
        history.replace({ ...history.location, pathname: pathnameParts.join('/') });
      }
    },
    [history]
  );

  return replacePageInBrowserHistory;
};
