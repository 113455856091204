import { animationTransition } from '@shared/utils/animationTransition';
import { Flex, TextV2 } from '@withjoy/joykit';
import React, { useCallback, useEffect, useState } from 'react';

interface FloatingNotificationProps {
  text?: string;
}

const FloatingNotification: React.FC<FloatingNotificationProps> = ({ text }) => {
  const [visible, setVisible] = useState(true);

  const handleDocumentClick = useCallback(() => {
    setVisible(false);
  }, [setVisible]);

  useEffect(() => {
    document.addEventListener('click', handleDocumentClick);

    return () => document.removeEventListener('click', handleDocumentClick);
  }, [handleDocumentClick]);

  return (
    <Flex
      position="absolute"
      top={{ _: '110px', md: '45px' }}
      width="245px"
      padding={4}
      borderRadius={1}
      backgroundColor="#333333E6"
      visibility={visible ? 'visible' : 'hidden'}
      opacity={visible ? 1 : 0}
      transition={animationTransition('opacity', 'visibility')}
    >
      <TextV2 typographyVariant="label2" color="mono4">
        {text || 'Check the preview of your card and make sure every detail is correct.'}
      </TextV2>
    </Flex>
  );
};

export default FloatingNotification;
