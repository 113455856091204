import { ApolloError } from '@apollo/client';
import { createTelemetryObject, createTelemetry } from '@shared/core/analytics';
import { PageFunctionType } from '@shared/core/analytics/telemetry/types';
import { DesignLayoutType, EventPageVisibility, LeadStateV2 } from '@graphql/generated';
import { GuestSiteQueryParams } from './packages/layout-engine/common/utils/useGuestSiteQueryParams';

export type PagesForTelemetry = 'welcome' | 'faq' | 'story' | 'travel' | 'tidbits' | 'registrylinks' | 'schedule' | 'moments' | 'weddingparty' | 'rsvp' | 'accommodations';

export type SecurityInfo = {
  requiresPassword: boolean;
  isUnlocked?: boolean;
};

export type EnabledPagesInfo = {
  slug: string;
  title: string;
  visibility: EventPageVisibility;
};

export type RoomBlocksInfo = {
  leadState?: LeadStateV2;
  availableCount: number;
  daysUntilSoonestCutOff?: number;
};

export type GuestSiteLoadTelemetry = {
  layout: DesignLayoutType;
  userRole: string;
  security: SecurityInfo;
  enabledPages: EnabledPagesInfo[];
  daysUntilEvent?: number;
  roomBlocks: RoomBlocksInfo;
} & GuestSiteQueryParams;

const pages: { [tab in PagesForTelemetry]: PageFunctionType } = {
  welcome: (userRole: string, args: GuestSiteQueryParams) => ({
    category: 'wedding',
    pagePrefix: 'guest',
    page: 'Welcome',
    action: 'ViewWelcome',
    extraPageInfo: {
      role: userRole,
      ...args
    }
  }),
  faq: (userRole: string, args: GuestSiteQueryParams) => ({
    category: 'wedding',
    pagePrefix: 'guest',
    page: 'faq',
    action: 'ViewFAQ',
    extraPageInfo: {
      role: userRole,
      ...args
    }
  }),
  story: (userRole: string, args: GuestSiteQueryParams) => ({
    category: 'wedding',
    pagePrefix: 'guest',
    page: 'story',
    action: 'ViewStory',
    extraPageInfo: {
      role: userRole,
      ...args
    }
  }),
  tidbits: (userRole: string, args: GuestSiteQueryParams) => ({
    category: 'wedding',
    pagePrefix: 'guest',
    page: 'tidbits',
    action: 'ViewTidbits',
    extraPageInfo: {
      role: userRole,
      ...args
    }
  }),
  travel: (userRole: string, args: GuestSiteQueryParams) => ({
    category: 'wedding',
    pagePrefix: 'guest',
    page: 'travel',
    action: 'ViewTravel',
    extraPageInfo: {
      role: userRole,
      ...args
    }
  }),
  registrylinks: (userRole: string, args: GuestSiteQueryParams) => ({
    category: 'wedding',
    pagePrefix: 'guest',
    page: 'registrylinks',
    action: 'ViewRegistryLinks',
    extraPageInfo: {
      role: userRole,
      ...args
    }
  }),
  schedule: (userRole: string, args: GuestSiteQueryParams) => ({
    category: 'wedding',
    pagePrefix: 'guest',
    page: 'schedule',
    action: 'ViewSchedule',
    extraPageInfo: {
      role: userRole,
      ...args
    }
  }),
  moments: (userRole: string, args: GuestSiteQueryParams) => ({
    category: 'wedding',
    pagePrefix: 'guest',
    page: 'moments',
    action: 'ViewMoments',
    extraPageInfo: {
      role: userRole,
      ...args
    }
  }),
  weddingparty: (userRole: string, args: GuestSiteQueryParams) => ({
    category: 'wedding',
    pagePrefix: 'guest',
    page: 'weddingParty',
    action: 'ViewWeddingParty',
    extraPageInfo: {
      role: userRole,
      ...args
    }
  }),
  rsvp: (userRole: string, args: GuestSiteQueryParams) => ({
    category: 'wedding',
    pagePrefix: 'guest',
    page: 'RSVP',
    action: 'ViewRSVP',
    extraPageInfo: {
      role: userRole,
      ...args
    }
  }),
  accommodations: (userRole: string, args: GuestSiteQueryParams) => ({
    category: 'wedding',
    pagePrefix: 'guest',
    page: 'accommodations',
    action: 'ViewAccommodations',
    extraPageInfo: {
      role: userRole,
      ...args
    }
  })
};

const guestSiteTelemetry = createTelemetryObject({
  actions: {
    guestSiteLoaded: (args: GuestSiteLoadTelemetry) => ({
      category: 'wedding',
      pagePrefix: 'guest',
      page: 'GuestSite',
      action: 'GuestSiteLoad',
      extraInfo: { ...args }
    }),

    trackError: (mutation: string, error: ApolloError | Error | unknown, extra = {}) => ({
      category: 'wedding',
      action: 'Mutation',
      actionType: 'error',
      extraInfo: { error, mutation, ...extra }
    }),

    adminPreviewClick: () => ({
      category: 'admin',
      action: 'OpenWebsite',
      actionType: 'click',
      label: 'CharmPreview'
    }),

    announcementBannerChange: (title: string, status: 'Shown' | 'Dismissed') => ({
      action: 'AnnouncementBannerStatus',
      category: 'wedding',
      label: title,
      extraInfo: {
        status: status
      }
    }),

    notPublishedBanner: (label: 'Shown' | 'Dismissed' | 'Published') => ({
      action: 'NotPublishedWarningStatus',
      category: 'wedding',
      label
    }),

    // MENU
    navMenuOpenedChange: (label: 'opened' | 'closed') => ({
      action: 'NavMenuOpened',
      category: 'wedding',
      label
    }),

    navMenuItemClicked: (pageClicked: string) => ({
      action: 'NavMenuItemClicked',
      category: 'wedding',
      actionType: 'click',
      label: pageClicked
    }),

    // Photo Editor
    photoEditorOpened: (label: 'opened' | 'closed') => ({
      action: 'PagePhotoEditorStatus',
      category: 'wedding',
      label
    }),

    photoEditorChangePhotoClicked: () => ({
      action: 'PagePhotoEditorChangePhoto',
      actionType: 'click',
      category: 'wedding'
    }),

    photoEditorUpdateFocus: (previewsFocus: unknown, currentFocus: unknown) => ({
      action: 'PagePhotoEditorFocusChanged',
      category: 'wedding',
      extraInfo: {
        previewsFocus,
        currentFocus
      }
    }),

    // Welcome Section Aloha
    welcomeViewDetailsButtonClicked: () => ({
      action: 'WelcomeViewDetailsButtonClicked',
      actionType: 'click',
      category: 'wedding'
    }),

    welcomeRSVPButtonClicked: () => ({
      action: 'WelcomeRSVPButtonClicked',
      actionType: 'click',
      category: 'wedding'
    }),

    welcomeNotYouButtonClicked: () => ({
      action: 'WelcomeRSVPButtonClicked',
      actionType: 'click',
      category: 'wedding'
    }),

    // APP Section
    weHaveAnAppClicked: (app: string) => ({
      action: 'WeHaveAnAppClicked',
      actionType: 'click',
      category: 'wedding',
      label: app
    }),

    editEventClicked: () => ({
      action: 'EditEventClicked',
      actionType: 'click',
      category: 'wedding'
    }),

    // Footer
    footerItemClicked: (item: string) => ({
      action: 'FooterItemClicked',
      actionType: 'click',
      category: 'wedding',
      label: item
    })
  },
  pages
});

const { TelemetryProvider, useTelemetry: useGuestSiteTelemetry } = createTelemetry(guestSiteTelemetry);
export { TelemetryProvider, useGuestSiteTelemetry };
