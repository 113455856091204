type GetInitialsOptions = {
  /** @default none */
  transform?: 'uppercase' | 'lowercase' | 'none';
};

export function getInitialsFromName(name: string, options: GetInitialsOptions = {}): string {
  const { transform = 'none' } = options;
  return (
    name
      ?.split(' ')
      .map(x => {
        const char = x[0];
        return transform === 'uppercase' ? char.toLocaleUpperCase() : transform === 'lowercase' ? char.toLocaleLowerCase() : char;
      })
      .join('') ?? ''
  );
}
