import React, { useCallback, useEffect, useRef } from 'react';
import { Box, ButtonV2 } from '@withjoy/joykit';
import { useDisclosure } from '@withjoy/joykit';
import { ReactComponent as PhotoIcon } from '@assets/icons/photo.svg';
import { useTranslation } from '@shared/core/i18n';
import { editPhotoOverrides } from './EditPhoto.styles';
import { FocusPointDialog } from '../FocusPointDialog';
import { withWindow } from '@shared/utils/withWindow';
import { useEventUserRole } from '@shared/components/AuthProvider';
import { useGuestSiteState } from '@apps/guest/routes/GuestSite/GuestSite.state.provider';
import { pxToRem } from '@withjoy/joykit/theme';
import { NO_LEAD_BANNER_HEIGHT, NO_LEAD_BANNER_HEIGHT_MOBILE } from '@apps/guest/packages/layout-engine/widgets/Custom/Custom.constants';
import { animationTransitionExt } from '@shared/utils/animationTransition';

const EditPhoto: React.FC<{
  eventHandle: string;
  onEditPhotoOpenedChange: (opened: boolean) => void;
  onChangePhotoButtonClicked: () => void;
  onFocusPointChange: (lastPoint: unknown, newPoint: unknown) => void;
  onFocusPointChangeError: (error: unknown) => void;
}> = ({ eventHandle, onChangePhotoButtonClicked, onEditPhotoOpenedChange, onFocusPointChange, onFocusPointChangeError }) => {
  const { enabledAdminGuestSiteBannerDialog } = useGuestSiteState();
  const { t2 } = useTranslation('focusPoint');
  const tEditPhoto = t2('editPhoto');
  const { actionEditText } = tEditPhoto;

  const { isAdmin, isLoggedIn } = useEventUserRole();
  const { isOpen: isFocusPointDialogOpen, onOpen: openFocusPointDialog, onClose: closeFocusPointDialog } = useDisclosure();

  const handleClick = useCallback(() => {
    openFocusPointDialog();
    onEditPhotoOpenedChange && onEditPhotoOpenedChange(true);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openFocusPointDialog]);

  const handleOnClose = useCallback(() => {
    closeFocusPointDialog();
    onEditPhotoOpenedChange && onEditPhotoOpenedChange(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onEditPhotoOpenedChange]);

  const appRootRef = useRef<HTMLElement | null>(null);

  useEffect(() => {
    withWindow(global => {
      appRootRef.current = global.document.getElementById('root');
    });
  }, []);

  if (!appRootRef.current) {
    return null;
  }

  if (!isLoggedIn || !isAdmin) return null;

  return (
    <Box
      position="absolute"
      pointerEvents="all"
      top={{
        _: enabledAdminGuestSiteBannerDialog ? pxToRem(NO_LEAD_BANNER_HEIGHT_MOBILE + 24) : 6,
        sm2: enabledAdminGuestSiteBannerDialog ? pxToRem(NO_LEAD_BANNER_HEIGHT + 24) : 6
      }}
      transition={animationTransitionExt({ property: 'top', duration: '250ms', timingFunction: 'ease-in-out' })}
      right="1.5rem"
      zIndex={1}
    >
      <ButtonV2 padding={5} startIcon={() => <PhotoIcon />} onClick={handleClick} backgroundColor={'white'} fontSize="15px" lineHeight={'120%'} overrides={editPhotoOverrides}>
        {actionEditText}
      </ButtonV2>
      <FocusPointDialog
        onFocusPointChangeError={onFocusPointChangeError}
        onFocusPointChange={onFocusPointChange}
        onChangePhotoButtonClicked={onChangePhotoButtonClicked}
        eventHandle={eventHandle}
        isOpen={isFocusPointDialogOpen}
        onClose={handleOnClose}
      />
    </Box>
  );
};

EditPhoto.displayName = 'EditPhoto';

export { EditPhoto };
