import React from 'react';
import { ReactComponent as JoyLogo } from '@assets/icons/logo.svg';
import { JoyLogoLoader } from '@shared/components/JoyLogoLoader';
import { useServiceCenterController } from '@apps/serviceCenter/ServiceCenter.controller';
import { Box, Flex, TextV2, IconV2 } from '@withjoy/joykit';
import { useAccountRouteHelper } from '@apps/account/useAccountHelper';
import { useRouterHelper } from '@shared/core';
import { routePaths } from '@apps/serviceCenter/route.paths';
import { Link as RouterLink } from '@react-router';
import { ReactComponent as ChatIcon } from '@assets/icons/iconChat.svg';
import { styles } from '@apps/serviceCenter/ServiceCenter.styles';
import { OrderReceipt } from '@withjoy/joykit/icons';
import { useFeatureValue } from '@shared/core/featureFlags';

export const ServiceCenter = () => {
  const { loading: isEnableNewPrintExperienceLoading, value: enableNewPrintExperienceValue } = useFeatureValue('enableNewPrintExperience');
  const { isLoading, isSuperAdmin, currentUserName } = useServiceCenterController();
  const accountHelper = useAccountRouteHelper();
  const { buildPath } = useRouterHelper();

  if (isLoading || isEnableNewPrintExperienceLoading) {
    return <JoyLogoLoader loaderKey="service-center" />;
  }

  if (isSuperAdmin) {
    return (
      <Box as="main">
        <Flex alignContent="center" paddingX={4} paddingY={4}>
          <JoyLogo />
          <TextV2 typographyVariant="Hed40" marginLeft={4}>
            Service Center
          </TextV2>
        </Flex>
        <Flex width="100%" flexDirection="column" alignItems="center" justifyContent="center" marginTop={10}>
          <TextV2 typographyVariant="hed5">Hello, {currentUserName}</TextV2>
          <Flex alignItems="center" marginTop={6} columnGap={8} rowGap={8} flexDirection={['column', null, 'row']}>
            <RouterLink to={buildPath(routePaths.messages.path)}>
              <Box __css={styles.actionContainer}>
                <Flex flexDirection="column" alignItems="center">
                  <ChatIcon height={48} width={48} />
                  <TextV2 typographyVariant="hed2">Messages</TextV2>
                </Flex>
              </Box>
            </RouterLink>
            <RouterLink to={buildPath(routePaths.eventSafetyRating.path)}>
              <Box __css={styles.actionContainer}>
                <Flex flexDirection="column" alignItems="center">
                  <TextV2 textAlign="center" typographyVariant="hed2">
                    Event Safety Rating
                  </TextV2>
                </Flex>
              </Box>
            </RouterLink>
            {!!enableNewPrintExperienceValue && (
              <RouterLink to={buildPath(routePaths.print.path)}>
                <Box __css={styles.actionContainer}>
                  <Flex flexDirection="column" alignItems="center">
                    <IconV2 size="lg">
                      <OrderReceipt />
                    </IconV2>
                    <TextV2 textAlign="center" typographyVariant="hed2">
                      Print Order Management
                    </TextV2>
                  </Flex>
                </Box>
              </RouterLink>
            )}
          </Flex>
        </Flex>
      </Box>
    );
  } else {
    accountHelper.signInAndReturnToCurrentPath(['google']);
    return null;
  }
};
