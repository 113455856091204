import { DesignLayoutType, EventPageFragment, EventPageType } from '@graphql/generated';
import { GuestSiteEvent } from './layout.types';

const nonSupportedEventPageSet = new Set([EventPageType.guests, EventPageType.tidbits]);
export const isSupportedEventPage = (pageType: EventPageType) => {
  return !nonSupportedEventPageSet.has(pageType);
};

export const findEventPageByPageSlug = (pages: ReadonlyArray<EventPageFragment>, target: string): Readonly<EventPageFragment> | undefined => {
  return pages.find(page => isSupportedEventPage(page.type) && page.pageSlug === target);
};

export const findEventPageByType = (pages: ReadonlyArray<EventPageFragment>, target: EventPageType): Readonly<EventPageFragment> | undefined => {
  return pages.find(page => isSupportedEventPage(page.type) && page.type === target);
};

export const shouldRenderGraphicAccent = (accent: Maybe<string>) => !!accent && accent !== 'blank';

export const getEventMenuPropsFromEvent = (event: GuestSiteEvent) => {
  return {
    eventId: event?.id || '',
    layoutType: event?.eventDesign?.websiteLayout.layoutType || DesignLayoutType.aloha,
    dateInMilliseconds: event.info?.dateV0918?.milliseconds || 0,
    pages: event?.pages || []
  };
};
