import React from 'react';
import { Flex, TextV2 } from '@withjoy/joykit';
import { StationeryPrintOrderDetailsFragment, StationeryTemplateCategoryEnum } from '@graphql/generated';
import ReceiptBlock from '@apps/card/components/Blocks/ReceiptBlock';
import { useTranslation } from '@shared/core';
import { minorToMajorUnits } from '@apps/card/hooks/useCalculatePrintOrderPrice';
import { PRODUCT_OFFERINGS } from '@apps/card/routes/Dashboard/components/ConciergeServiceSection/ConciergeServiceSection.config';

export const ConciergePaymentSummary = ({ order }: { order: StationeryPrintOrderDetailsFragment }) => {
  const { t } = useTranslation('stationery');
  const trans = t('cardCustomizer', 'priceSummary');
  const categoryName = t('stationeryTemplateCategoryToTile', StationeryTemplateCategoryEnum.invitation)();
  const { taxInMinorUnits, priceInMinorUnits, quantity } = order;

  const taxAmount = taxInMinorUnits ? minorToMajorUnits(taxInMinorUnits) : 0;

  const summary: Record<'card' | 'shipping' | 'tax', Array<[label: string, value: string | undefined]>> & {
    total: string;
  } = {
    card: [[`Quantity: ${quantity} cards $${(minorToMajorUnits(priceInMinorUnits) / quantity).toFixed(2)} each`, `$${minorToMajorUnits(priceInMinorUnits).toFixed(2)}`]],
    shipping: [['Standard DHL Shipping', 'FREE']],
    tax: [['Sales Tax', `$${taxAmount.toFixed(2)}`]],
    total: `$${(minorToMajorUnits(priceInMinorUnits) + taxAmount).toFixed(2)}`
  };

  const suiteName = PRODUCT_OFFERINGS.find(({ identifier }) => identifier === order.designSetName)?.longName;

  return (
    <Flex
      width={{ _: '100%', md: '40%' }}
      alignSelf={{ _: 'center', md: 'flex-end' }}
      paddingX={{ _: 6, md: 0 }}
      flexDirection={{ _: 'column', md: 'row' }}
      rowGap={10}
      columnGap={140}
    >
      <Flex flexDirection="column" flex={1}>
        <Flex flexDirection="column" rowGap={6}>
          <TextV2 typographyVariant="hed3">{trans.orderSummaryLabel()}</TextV2>
          <ReceiptBlock title={categoryName} subTitle={suiteName} lines={summary.card} />
          <ReceiptBlock title="Shipping Method" lines={summary.shipping} />
          {!!taxInMinorUnits && <ReceiptBlock title="Taxes" lines={summary.tax} />}
          <Flex flexDirection="column" paddingTop={3} borderTop="1px solid #EBEBEB">
            <Flex justifyContent="space-between" alignItems="center">
              <TextV2 typographyVariant="hed3" fontSize="15px">
                {trans.totalLabel()}
              </TextV2>
              <TextV2 typographyVariant="hed3">{summary.total}</TextV2>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};
