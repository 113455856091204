import React from 'react';
import merge from 'deepmerge';
import {
  styles,
  StyledImage,
  Grid,
  Cell,
  FocalPoint,
  ImageContainer,
  HighlightMask,
  dialogOverrides,
  dialogOverridesMobile,
  dialogOverridesTablet,
  DialogContent,
  DialogContainer,
  dialogSmallDesktopOverrides,
  DialogBody
} from './FocusPointDialog.styles';
import { TextV2, Box, DialogV2, ButtonV2, Flex, JoyKitThemeProvider } from '@withjoy/joykit';
import { useTranslation } from '@shared/core/i18n';
import { EventPageType, PhotoXPosition, PhotoYPosition } from '@graphql/generated';
import { useFocusPointController } from './FocusPointDialog.controller';
import { useTransition } from 'react-spring';
import { PagePhotoSettings } from '@shared/components/FocusPointEditor';
import { useApolloClient } from '@apollo/client';
import { useFeatureValue } from '@shared/core/featureFlags';
export interface FocusPointDialogProps {
  eventHandle: string;
  isOpen: boolean;
  onChangePhotoButtonClicked: () => void;
  onFocusPointChange: (lastPoint: unknown, newPoint: unknown) => void;
  onFocusPointChangeError: (error: unknown) => void;
  onClose: () => void;
}

const YPositions = [PhotoYPosition.top, PhotoYPosition.center, PhotoYPosition.bottom];
const XPositions = [PhotoXPosition.left, PhotoXPosition.center, PhotoXPosition.right];

const Mask: React.FC<{ isActive: boolean }> = ({ isActive }) => {
  const transition = useTransition(isActive, null, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: { duration: 400 }
  });
  return <>{transition.map(({ item, key, props: style }) => item && <HighlightMask key={key} style={style} __css={styles.highlight} />)}</>;
};

export const FocusPointDialog: React.FC<FocusPointDialogProps> = ({ eventHandle, isOpen, onClose, onChangePhotoButtonClicked, onFocusPointChange, onFocusPointChangeError }) => {
  const {
    aspectRatio,
    changeFocusPoint,
    dialogRef,
    focusPoint,
    isMobileScreen,
    isTabletScreen,
    isSmallDesktopScreen,
    photo,
    saveFocusPoint,
    resetFocusPoint,
    showTopRadius,
    page,
    pageId,
    eventId,
    previewPosition,
    reflectPhotoChange
  } = useFocusPointController({ isOpen, onFocusPointChange, onFocusPointChangeError });

  const pagePhotoFocusEnabled = useFeatureValue('pagePhotoFocus').value === 'on';

  const persistFocusPoint = () => {
    saveFocusPoint();
    onClose();
  };
  const closeDialog = () => {
    resetFocusPoint();
    onClose();
  };
  const { t2 } = useTranslation('focusPoint');
  const tEditPhoto = t2('focusPointDialog');
  const { title, subtitle, changePhotoText, applyButtonText } = tEditPhoto;
  const getOverridesStyles = () => {
    if (isMobileScreen) {
      return merge(
        dialogOverridesMobile,
        showTopRadius
          ? {
              Content: {
                props: {
                  borderTopLeftRadius: '12px',
                  borderTopRightRadius: '12px'
                }
              }
            }
          : {}
      );
    }
    if (isTabletScreen) {
      return dialogOverridesTablet;
    }
    if (isSmallDesktopScreen) {
      return dialogSmallDesktopOverrides;
    }
    return dialogOverrides;
  };

  // remove the animation on render/dismount till we get a better spec
  // const transition = {
  //   from: { position: 'relative', opacity: 0, transform: 'translateY(100%)' },
  //   enter: { position: 'relative', opacity: 1, transform: 'translateY(0px)' },
  //   leave: { position: 'relative', opacity: 0, transform: 'translateY(100%)' },
  //   config: { duration: 400 }
  // };

  const client = useApolloClient();

  const onDeletePhotoSuccess = (derivedPageIdToDelete: string, derivedPageType: EventPageType | '', actualPageId: string, actualPageType: EventPageType | undefined) => {
    client.cache.modify({
      id: client.cache.identify({ __typename: 'Event', id: eventId }),
      fields: {
        pages(
          existingPages: Readonly<Object> &
            {
              __ref: string;
            }[] = [],
          { readField }
        ) {
          const updatedPages = existingPages.map(pageRef => {
            const pageId = readField('id', pageRef);
            if (pageId === actualPageId || pageId === derivedPageIdToDelete) {
              client.cache.modify({
                id: pageRef.__ref,
                fields: {
                  photo() {
                    return null;
                  }
                }
              });
            }

            return pageRef;
          });
          return updatedPages;
        }
      }
    });
    if (derivedPageType === EventPageType.welcome) {
      // on deleteing the welcome page photo some other fields values are getting updated in BE, so refetching the queries
      client.refetchQueries({ include: ['EventWebsiteGuest'] });
    }
  };

  return (
    <JoyKitThemeProvider>
      <DialogContainer
        id="FocusPointDialog"
        ref={dialogRef}
        overrides={
          pagePhotoFocusEnabled
            ? {
                Content: { props: { width: ['100%', '480px'] } },
                Body: { props: { paddingX: 8, paddingY: 8 } }
              }
            : getOverridesStyles()
        }
        // transition={transition}
        onClose={closeDialog}
        isOpen={isOpen}
        isDraggable={!isMobileScreen && !isTabletScreen}
        disableFocusLock={true}
      >
        {pagePhotoFocusEnabled ? (
          <DialogBody>
            <PagePhotoSettings
              isOpen={isOpen}
              handleOnClose={closeDialog}
              handleOnCancel={closeDialog}
              imageData={photo}
              pageType={page || EventPageType.welcome}
              pageId={pageId}
              showNavigateBack={false}
              onPhotoDeleteSuccess={onDeletePhotoSuccess}
              telemetryCategory="wedding"
              onFocusPointChange={previewPosition}
              onFocusUpdateSuccess={(_, imageInfo) => reflectPhotoChange(imageInfo)}
            />
          </DialogBody>
        ) : (
          <>
            <DialogV2.CloseButton />
            <DialogBody>
              <DialogContent stack="vertical" alignItems={'center'} spacing={0} marginY={6}>
                <Box marginBottom={6}>
                  <TextV2 typographyVariant={'hed6'} as="h1" textAlign={'center'} letterSpacing="-0.015em">
                    {title}
                  </TextV2>
                  <TextV2 typographyVariant="body1" as="h2" marginTop={3} textAlign={'center'} letterSpacing="-0.01em">
                    {subtitle}
                  </TextV2>
                </Box>
                <ImageContainer
                  __css={styles.imageContainer}
                  aspectRatio={aspectRatio.aspectRatio.replace(':', '/')}
                  maxWidth={aspectRatio.size.container[0]}
                  maxHeight={aspectRatio.size.container[1]}
                >
                  <StyledImage __css={styles.image} backgroundImage={photo?.url || ''} maxWidth={aspectRatio.size.image[0]} maxHeight={aspectRatio.size.image[1]}>
                    <Grid __css={styles.grid}>
                      {YPositions.map(y =>
                        XPositions.map(x => (
                          <Cell
                            key={`align-${y}-${x}`}
                            __css={styles.cell}
                            isActive={focusPoint[0] === x && focusPoint[1] === y}
                            onClick={changeFocusPoint(PhotoXPosition[x], PhotoYPosition[y])}
                          >
                            <FocalPoint __css={styles.focalPoint} />
                            <Mask isActive={focusPoint[0] === x && focusPoint[1] === y} />
                          </Cell>
                        ))
                      )}
                    </Grid>
                  </StyledImage>
                </ImageContainer>
                <Flex __css={styles.buttonContainer}>
                  <ButtonV2
                    onClick={onChangePhotoButtonClicked}
                    variant={'ghost'}
                    size="md"
                    as="a"
                    color="black"
                    href={`/${eventHandle}/edit/photos`}
                    target="_blank"
                    textDecoration={'none'}
                    marginTop={4}
                    height={'18px'}
                  >
                    {changePhotoText}
                  </ButtonV2>
                  <ButtonV2 intent="primary" marginTop={6} onClick={persistFocusPoint}>
                    {applyButtonText}
                  </ButtonV2>
                </Flex>
              </DialogContent>
            </DialogBody>
          </>
        )}
      </DialogContainer>
    </JoyKitThemeProvider>
  );
};

FocusPointDialog.displayName = 'FocusPointDialog';
