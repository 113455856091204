import { LayerData } from '@apps/card/routes/CardCustomizer/components/Layer.types';

// Thumbnails
import CARD_BACK_TYPOGRAPHY from './images/typography.png';
import CARD_BACK_MONOGRAM from './images/monogram.png';
import CARD_BACK_BLANK from './images/blank.png';
import CARD_BACK_KINDLYRSVP from './images/kindly_rsvp.back.png';
import CARD_BACK_THEDETAILS from './images/the_details.back.png';
import CARD_BACK_LETSCELEBRATE from './images/lets_celebrate.back.png';
import CARD_BACK_ADORABLE_THANK_YOU from './images/adorable.thankyou.jpeg';
import CARD_BACK_MANY_THANKS_THANK_YOU from './images/many_thanks.thankyou.jpeg'; // Thank You Cards start here
import CARD_BACK_MONOGRAM_THANK_YOU from './images/monogram.thankyou.jpeg';
import CARD_BACK_NAMES_THANK_YOU from './images/names.thankyou.jpeg';
import CARD_BACK_PHOTO_BLANK_THANK_YOU from './images/photo_blank.thankyou.jpeg';
import CARD_BACK_PHOTO_NOTE_THANK_YOU from './images/photo_note.thankyou.jpeg';

// LayerData[]
import cardBackTypography from './json/back.typography.invite.json';
import cardBackMonogram from './json/back.monogram.invite.json';
import cardBackKindlyRsvp from './json/kindly_rsvp.back.json';
import cardBackTheDetails from './json/the_details.back.json';
import cardBackLetsCelebrate from './json/lets_celebrate.back.json';
import photoQrCode from './json/82bb30b9-81a1-42b7-b146-8e2c6df2e606.json';
import photoText from './json/44a15e27-82b3-40c5-aacf-d7e31744fce5.json';
import photoMonogram from './json/be958803-91f6-40b5-aff3-07a64baffc91.json';
import photoInset from './json/017e57b8-f1b8-43c7-ad99-7668835820f4.json';
import photoFull from './json/74b59724-4fff-430d-b721-c653c438d949.json';
import uploadYourDesign from './json/21fcbf2f-a8bc-416e-b531-f23043e3299f.json';
import cardBackAdorableThankYou from './json/adorable.thankyou.json'; // Thank You Cards start here
import cardBackManyThanksThankYou from './json/many_thanks.thankyou.json';
import cardBackMonogramThankYou from './json/monogram.thankyou.json';
import cardBackNamesThankYou from './json/names.thankyou.json';
import cardBackPhotoBlankThankYou from './json/photo_blank.thankyou.json';
import cardBackPhotoNoteThankYou from './json/photo_note.thankyou.json';

// A subset of StationeryTemplate
type Template = {
  name: string;
  thumbnailUrl: string;
  card: {
    front: {
      layers: LayerData[];
    };
  };
};

function createCardBack(name: string, thumbnailUrl: string, layers: unknown) {
  return {
    name,
    thumbnailUrl,
    layers: layers as ReadonlyArray<LayerData>
  };
}

function createCardBackFromTemplate(name: string, templateAsUnknown: unknown) {
  const template = templateAsUnknown as Template;
  return {
    name,
    thumbnailUrl: template.thumbnailUrl,
    layers: template.card.front.layers
  };
}

export const LAYOUT_TO_TEMPLATE_REGULAR = {
  lets_celebrate: createCardBack("Let's Celebrate", CARD_BACK_LETSCELEBRATE, cardBackLetsCelebrate),
  photo_text: createCardBackFromTemplate('Photo Text', photoText),
  photo_inset: createCardBackFromTemplate('Photo Inset', photoInset),
  the_details: createCardBack('The Details', CARD_BACK_THEDETAILS, cardBackTheDetails),
  kindly_rsvp: createCardBack('Kindly RSVP', CARD_BACK_KINDLYRSVP, cardBackKindlyRsvp),
  photo_qr_code: createCardBackFromTemplate('Photo QR Code', photoQrCode),
  photo_monogram: createCardBackFromTemplate('Photo Monogram', photoMonogram),
  monogram: createCardBack('Monogram', CARD_BACK_MONOGRAM, cardBackMonogram),
  photo_full: createCardBackFromTemplate('Photo Full', photoFull),
  typography: createCardBack('Typography', CARD_BACK_TYPOGRAPHY, cardBackTypography),
  upload_your_design: createCardBackFromTemplate('Upload Your Design', uploadYourDesign),
  blank: createCardBack('Blank', CARD_BACK_BLANK, [])
};

export const LAYOUT_TO_TEMPLATE_THANKYOU = {
  adorable_thank_you: createCardBack('Adorable', CARD_BACK_ADORABLE_THANK_YOU, cardBackAdorableThankYou),
  many_thanks_thank_you: createCardBack('Many Thanks', CARD_BACK_MANY_THANKS_THANK_YOU, cardBackManyThanksThankYou),
  monogram_thank_you: createCardBack('Monogram', CARD_BACK_MONOGRAM_THANK_YOU, cardBackMonogramThankYou),
  names_thank_you: createCardBack('Names', CARD_BACK_NAMES_THANK_YOU, cardBackNamesThankYou),
  photo_blank_thank_you: createCardBack('Photo Blank', CARD_BACK_PHOTO_BLANK_THANK_YOU, cardBackPhotoBlankThankYou),
  photo_note_thank_you: createCardBack('Photo Note', CARD_BACK_PHOTO_NOTE_THANK_YOU, cardBackPhotoNoteThankYou)
};

export const LAYOUT_TO_TEMPLATE = {
  ...LAYOUT_TO_TEMPLATE_REGULAR,
  ...LAYOUT_TO_TEMPLATE_THANKYOU
};

export function containsQrcode(templateId: string) {
  const templatesContainingQrCodes: (keyof typeof LAYOUT_TO_TEMPLATE_REGULAR)[] = ['lets_celebrate', 'the_details', 'kindly_rsvp', 'photo_qr_code'];
  return (templatesContainingQrCodes as string[]).includes(templateId);
}
