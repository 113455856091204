import React, { RefObject, useEffect, useRef } from 'react';
import { Box } from '@withjoy/joykit';
import { EventLogisticsBlock } from '../../../../components/EventLogisticsBlock';
import { alohaConfig } from '../../LayoutAloha.theme';
import { DateFragment, EventPageFragment } from '@graphql/generated';
import { useAlohaScrollWatch } from '../../hooks/useAlohaScrollWatch';
import { CtaButtons } from '@apps/guest/packages/layout-engine/components/CtaButtons/CtaButtons';
import { GraphQLError } from 'graphql';

const { simple } = alohaConfig.applicatorHtmlProps;

interface WelcomeCardProps {
  page: EventPageFragment;
  eventHandle: string;
  location?: string | null;
  finalizedDate?: Maybe<DateFragment & { timeString: string; dateString: string }>;
  hideEventCountdown: boolean;
  homeRef: RefObject<HTMLDivElement>;
  nextPageSlug?: Maybe<string>;
  onRSVPButtonClicked?: () => void;
  onViewDetailsButtonClicked?: () => void;
  onNotYouButtonClicked?: () => void;
  onNotYouErrorRequest?: (err: GraphQLError) => void;
}

export const WelcomeCard: React.FC<WelcomeCardProps> = ({
  homeRef,
  page,
  eventHandle,
  location,
  finalizedDate,
  hideEventCountdown,
  nextPageSlug,
  onRSVPButtonClicked,
  onViewDetailsButtonClicked,
  onNotYouButtonClicked,
  onNotYouErrorRequest,
  ...restProps
}) => {
  const { currentPage } = useAlohaScrollWatch(homeRef, page);
  const firstTimeOnWelcomePage = useRef(true);

  useEffect(() => {
    if (currentPage !== 'welcome' && firstTimeOnWelcomePage.current) {
      firstTimeOnWelcomePage.current = false;
    }
  }, [currentPage]);

  return (
    <Box
      key={page.pageSlug}
      data-testid={'page-welcome'}
      height={'100vh'}
      display={'flex'}
      alignItems="center"
      justifyContent={'center'}
      position={'relative'}
      flexDirection={'column'}
      paddingX={'15%'}
      overflowY={'hidden'}
      {...simple.prop}
      {...restProps}
    >
      <EventLogisticsBlock
        showViewDetailsButton={firstTimeOnWelcomePage.current}
        eventHandle={eventHandle}
        nextPageSlug={nextPageSlug}
        page={page}
        location={location}
        date={finalizedDate}
        hideEventCountdown={hideEventCountdown}
        shouldBreakDate
        onViewDetailsButtonClicked={onViewDetailsButtonClicked}
      >
        <CtaButtons onRSVPButtonClicked={onRSVPButtonClicked} onNotYouButtonClicked={onNotYouButtonClicked} onNotYouErrorRequest={onNotYouErrorRequest} />
      </EventLogisticsBlock>
    </Box>
  );
};
