import { useCallback } from 'react';
import { useHistory } from '@shared/core';
import { getSlugFromPathname } from '@apps/guest/packages/layout-engine/common/utils/useGuestSitePageChanged';
import { PagesRefs } from '@apps/guest/packages/layout-engine/layouts/LayoutAloha/LayoutAloha.types';

export const usePageRefFromUrl = () => {
  const history = useHistory();

  const getPageRefFromUrl = useCallback(
    (pagesRef: PagesRefs[]) => {
      const slugFromPathname = getSlugFromPathname(history.location.pathname)?.toLowerCase();
      const currentPage = pagesRef.find(pageRef => slugFromPathname && pageRef.page.pageSlug.toLowerCase() === slugFromPathname);

      return currentPage;
    },
    [history.location]
  );

  return getPageRefFromUrl;
};
