import React from 'react';
import { PrintOrderManagementState } from './state';
import { FilterBar } from './components/FilterBar';
import { ResultsTable } from './components/ResultsTable';

export const PrintOrderManagement: React.FC = () => {
  return (
    <PrintOrderManagementState>
      <FilterBar />
      <ResultsTable />
    </PrintOrderManagementState>
  );
};
