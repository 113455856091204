import { createTelemetryObject, createTelemetry } from '@shared/core/analytics';

const category = 'wedding';
const pagePrefix = 'guest';
const action = 'TravelButtonInteracted';

interface hotelExtraInfo {
  hotelName?: string;
  isHotelPreferred?: boolean;
  hotelPrice?: number;
  hotelStrikeThroughPrice?: number;
}

const actionProps = {
  action,
  category,
  pagePrefix
};

const telemObject = createTelemetryObject({
  pages: {
    customPage: (page: string) => ({
      category,
      pagePrefix,
      page
    })
  },
  actions: {
    selectRoomClicked: (page: string, args: hotelExtraInfo) => ({
      ...actionProps,
      page,
      extraInfo: {
        name: 'selectRoomClicked',
        ...args
      }
    }),
    shareRoomClicked: (page: string, args: hotelExtraInfo) => ({
      ...actionProps,
      page,
      extraInfo: {
        name: 'shareRoomClicked',
        ...args
      }
    }),
    hotelPinSelected: (page: string, args: hotelExtraInfo) => ({
      ...actionProps,
      page,
      extraInfo: {
        name: 'hotelPinSelected',
        ...args
      }
    }),
    hotelPinCardSelected: (page: string, args: hotelExtraInfo) => ({
      ...actionProps,
      page,
      extraInfo: {
        name: 'hotelPinCardSelected',
        ...args
      }
    }),
    accommodationsMapClicked: (page: string) => ({
      ...actionProps,
      page,
      extraInfo: {
        name: 'accommodationsMapClicked'
      }
    }),
    mapExpanded: (page: string) => ({
      ...actionProps,
      page,
      extraInfo: {
        name: 'mapExpanded'
      }
    }),
    mapContracted: (page: string) => ({
      ...actionProps,
      page,
      extraInfo: {
        name: 'mapContracted'
      }
    }),
    onClickConciergeEmailLink: (page: string, link: string) => ({
      ...actionProps,
      page,
      extraInfo: {
        link,
        name: 'linkClicked',
        source: 'AccommodationsNote'
      }
    }),
    leadGenBannerCtaClicked: () => ({
      category: 'GuestAccommodations',
      action: 'TravelButtonInteracted',
      label: 'getStarted',
      extraInfo: {
        name: 'leadGenBanner'
      }
    }),
    leadGenDialogCtaClicked: () => ({
      category: 'GuestAccommodations',
      action: 'TravelButtonInteracted',
      label: 'getStarted',
      extraInfo: {
        name: 'leadGenDialog'
      }
    }),
    leadGenDialogPromptedClicked: () => ({
      category: 'GuestAccommodations',
      action: 'TravelButtonInteracted',
      label: 'selectRooms',
      extraInfo: {
        name: 'leadGenDialog_prompted'
      }
    }),
    leadGenDialogDismissClicked: () => ({
      category: 'GuestAccommodations',
      action: 'TravelButtonInteracted',
      label: 'Close',
      extraInfo: {
        name: 'leadGenDialog_dismiss'
      }
    })
  }
});

const { TelemetryProvider: customPageTelemetryProvider, useTelemetry: useCustomPageTelemetry } = createTelemetry(telemObject);

export { customPageTelemetryProvider, useCustomPageTelemetry };
