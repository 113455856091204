import { useMemo } from 'react';

import { EventPageType, EventPageFragment } from '@graphql/generated';
import { RoutesPaths } from '@shared/core';

const SUPPORTED_PAGES = [
  EventPageType.welcome,
  EventPageType.story,
  EventPageType.vip,
  EventPageType.faq,
  EventPageType.schedule,
  EventPageType.travel,
  EventPageType.moments,
  EventPageType.registry,
  EventPageType.rsvp,
  EventPageType.tidbits,
  EventPageType.custom
] as const;

const supportedPagesSet = new Set<EventPageType>(SUPPORTED_PAGES);

export type SupportedEventPages = typeof SUPPORTED_PAGES[number];

export type EventPageRoutePaths = Partial<RoutesPaths<SupportedEventPages>>;

export const useEventPageRouting = (eventHandle: string, pages: EventPageFragment[]): EventPageRoutePaths => {
  return useMemo(() => {
    if (!eventHandle || !eventHandle.trim().length) {
      return {};
    }
    return pages.reduce((acc, page) => {
      if (supportedPagesSet.has(page.type)) {
        acc[page.type as SupportedEventPages] = {
          path: `/${eventHandle}/${page.pageSlug}`,
          goToPath: () => page.pageSlug
        };
      }
      return acc;
    }, {} as EventPageRoutePaths);
  }, [eventHandle, pages]);
};
