import { ProductOffering } from './ConciergeServiceSection.types';
import { v4 as uuid } from 'uuid';

const HIGHLIGHT_ENVELOPE_ADDRESSING = { text: 'Optional recipient and return address printing' };

export const PRODUCT_OFFERINGS: Array<ProductOffering> = [
  {
    id: uuid(),
    identifier: 'wildestDream',
    shortName: 'Wildest Dream',
    longName: 'Wildest Dream Wedding Invitation Suite',
    costPerHundredString: '$8.12',
    descriptionHighlights: [
      { text: 'Immerse yourself in the timeless allure of the Wildest Dreams invitation set' },
      { text: 'Customize the information card with the option to add your website or RSVP QR code' },
      { text: 'Each invitation is encased in a transparent delicate floral print vellum bellyband' }
    ],
    suiteHighlights: [
      { text: 'Personalization with Joy concierge design services' },
      { text: 'Gold foil invitation card, 5”x 7“ on premium 380GSM paper' },
      { text: 'Details card, 4.2” x 5.5” on premium 380GSM paper' },
      { text: 'Vellum bellyband 80GSM ' },
      { text: 'Premium white envelope' },
      HIGHLIGHT_ENVELOPE_ADDRESSING
    ],
    videoUrl: 'https://player.vimeo.com/video/947047706?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479',
    videoThumbnailUrl: 'https://i.vimeocdn.com/video/1853442320-6f5b0b94f1e861bc7d5b8da0d7f23132d42880f26d2f5821f02ff6287c29c357-d_1280x720?r=pad',
    imageUrl: 'https://withjoy.com/assets/public/messaging/partnerships/paperlust/invitation-suites/wildest-dreams/wildest-dreams-suite-1-main.jpg?v=2'
  },
  {
    id: uuid(),
    identifier: 'together',
    shortName: 'Together',
    longName: 'Together Wedding Invitation Suite',
    costPerHundredString: '$5.36',
    videoUrl: 'https://player.vimeo.com/video/947047579?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479',
    videoThumbnailUrl: 'https://i.vimeocdn.com/video/1853442234-787d4b800726a285c5dd2edc49aaab297f6efaa18a921d09b01bb853f971a48e-d_1280x720?r=pad',
    imageUrl: 'https://withjoy.com/assets/public/messaging/partnerships/paperlust/invitation-suites/together-gatefold/together-gatefold-1-main.jpg?v=2',
    descriptionHighlights: [
      { text: 'Timeless photo gatefold invitation includes all the information for guests on one folded invitation' },
      { text: 'This bundle includes a delicate vellum bellyband to wrap around your invitations for a finished look' },
      { text: 'Wax seals are not included, but can easily be purchased from suggested vendors' }
    ],
    suiteHighlights: [
      { text: 'Personalization with Joy concierge design services' },
      { text: 'Photo gatefold  wedding invitation card, 10” x 7” on 380 GSM premium paper' },
      { text: 'Vellum bellyband 80GSM' },
      { text: 'Premium white envelope' },
      HIGHLIGHT_ENVELOPE_ADDRESSING
    ]
  },
  {
    id: uuid(),
    identifier: 'sofia',
    shortName: 'Sofia',
    longName: 'Sofia Wedding Invitation Suite',
    costPerHundredString: '$9.64',
    videoUrl: 'https://player.vimeo.com/video/947047427?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479',
    videoThumbnailUrl: 'https://i.vimeocdn.com/video/1853441762-3cc48466d6550ed05108cb8f662fbd8e8446469012b444360a6f7961d28f950f-d_1280x720?r=pad',
    imageUrl: 'https://withjoy.com/assets/public/messaging/partnerships/paperlust/invitation-suites/sofia/sofia-1-main.jpg?v=2',
    descriptionHighlights: [
      { text: 'Eye catching modern suite printed on shimmery metallic paper for a subtle glow' },
      { text: 'Matching arch shaped wedding information card that fits perfectly with the main invitation' },
      { text: 'A small landscape arch RSVP card, with the option to add your wedding website QR code' }
    ],
    suiteHighlights: [
      { text: 'Personalization with Joy concierge design services' },
      { text: 'Wedding invitation card, 5” x 7” arch on 300 GSM metallic paper' },
      { text: 'Details card, 5.8” x 3.7” arch on 300 GSM metallic paper' },
      { text: 'RSVP card, 4.2” x 2.1” landscape arch on 300 GSM metallic paper' },
      { text: 'Premium almond color envelope' },
      HIGHLIGHT_ENVELOPE_ADDRESSING
    ]
  },
  {
    id: uuid(),
    identifier: 'sage',
    shortName: 'Sage',
    longName: 'Sage Wedding Invitation Suite',
    costPerHundredString: '$12.20',
    videoUrl: 'https://player.vimeo.com/video/947047267?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479',
    videoThumbnailUrl: 'https://i.vimeocdn.com/video/1853441651-c49e0f48614b2978086924dd41716baf10bdeced9b21d1a967e2be77542f22d1-d_1280x720?r=pad',
    imageUrl: 'https://withjoy.com/assets/public/messaging/partnerships/paperlust/invitation-suites/sage/sage-1-main.jpg?v=2',
    descriptionHighlights: [
      { text: 'Deep forest colors combined with elegant neutrals combine for a refined but natural look' },
      { text: 'Each set comes with gold paper clips to keep all your cards together' },
      { text: 'Includes premium almond color envelopes included' }
    ],
    suiteHighlights: [
      { text: 'Personalization with Joy concierge design services' },
      { text: 'Wedding invitation card, 5” x 7” arch on Forest Green 300GSM paper with white ink' },
      { text: 'Details card, 4.2” x 5.5” curved edge on 300 GSM Almond Paper' },
      { text: 'RSVP card, 4.2” x 2.1” land scape arch on 300GSM paper' },
      { text: 'Premium Almond Envelope' },
      { text: 'Gold color paper clip' },
      HIGHLIGHT_ENVELOPE_ADDRESSING
    ]
  },
  {
    id: uuid(),
    identifier: 'milkAndHoney',
    shortName: 'Milk and Honey',
    longName: 'Milk and Honey Wedding Invitation Suite',
    costPerHundredString: '$10.47',
    videoUrl: 'https://player.vimeo.com/video/947047142?badge=0&autopause=0&player_id=0&app_id=58479',
    videoThumbnailUrl: 'https://i.vimeocdn.com/video/1853441650-1e9eab448236c13eb967d5aa9eb793b23c6f4b09754dc3e4739d10c4c05017d3-d_1280x720?r=pad',
    imageUrl: 'https://withjoy.com/assets/public/messaging/partnerships/paperlust/invitation-suites/milk-and-honey/milk-and-honey-1-main.jpg?v=2',
    descriptionHighlights: [
      { text: 'Earthy tone invitation package perfect for those wanting neutral colors' },
      { text: 'The wedding details card features two-tone front and back with for wedding details and RSVP information' }
    ],
    suiteHighlights: [
      { text: 'Personalization with Joy concierge design services' },
      { text: 'Wedding invitation card, 5” x 7”, on 300GSM cotton' },
      { text: 'Wedding details card, 5” x 7” half arch on 300 GSM almond paper' },
      { text: 'Bellyband 120 GSM matte' },
      { text: 'Premium almond color envelopes with options to select to grey, or harvest' },
      HIGHLIGHT_ENVELOPE_ADDRESSING
    ]
  },
  {
    id: uuid(),
    identifier: 'jardin',
    shortName: 'Jardin',
    longName: 'Jardin Wedding Invitation Suite',
    costPerHundredString: '$4.29',
    videoUrl: 'https://player.vimeo.com/video/947046975?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479',
    videoThumbnailUrl: 'https://i.vimeocdn.com/video/1853441362-42f030c8a90dd23ddd39ced311850e1e069f336c0c1fb00f0bd88d70c72ea8c0-d_1280x720?r=pad',
    imageUrl: 'https://withjoy.com/assets/public/messaging/partnerships/paperlust/invitation-suites/jardin/jardin-1-main.jpg?v=2',
    descriptionHighlights: [
      { text: 'The Jardin gatefold invitation is for those flower and nature lovers, sure to set the tone for your special day' },
      { text: 'This all-in-one wedding invitation includes all your wedding day finer details and RSVP information' }
    ],
    suiteHighlights: [
      { text: 'Personalization with Joy concierge design services' },
      { text: 'Gatefold wedding invitation card, 10” x 7” on 380GSM premium paper' },
      { text: 'Premium envelopes in forest green or peach' },
      HIGHLIGHT_ENVELOPE_ADDRESSING
    ]
  },
  {
    id: uuid(),
    identifier: 'ivory',
    shortName: 'Ivory',
    longName: 'Ivory Wedding Invitation Suite',
    costPerHundredString: '$12.45',
    videoUrl: 'https://player.vimeo.com/video/947046859?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479',
    videoThumbnailUrl: 'https://i.vimeocdn.com/video/1853441091-25ae32d4ca89fb7e08092400588fbbe28ee9a7d9df56b0fdc69171f18f7a60c6-d_1280x720?r=pad',
    imageUrl: 'https://withjoy.com/assets/public/messaging/partnerships/paperlust/invitation-suites/ivory/ivory-1-main.jpg?v=2',
    descriptionHighlights: [
      { text: 'This set includes arch shaped invitations printed on beautiful cotton paper' },
      { text: 'Matching double-sided information card to include all your wedding day details' },
      { text: 'A small landscape arch shape RSVP card, with the option to add your wedding website QR code,' },
      { text: 'Premium black color envelopes included' }
    ],
    suiteHighlights: [
      { text: 'Personalization with Joy concierge design services' },
      { text: 'Wedding invitation card, 5” x 7” arch on premium 300 GSM cotten paper' },
      { text: 'Details card,  4.2” x 5.5” rounded edge on 300 GSM almond paper' },
      { text: 'RSVP card, 4.2” x 2.1” landscape arch on 270 GSM black paper with white ink' },
      { text: 'Premium black envelopes with white ink' },
      HIGHLIGHT_ENVELOPE_ADDRESSING
    ]
  },
  {
    id: uuid(),
    identifier: 'harmony',
    shortName: 'Harmony',
    longName: 'Harmony Wedding Invitation Suite',
    costPerHundredString: '$12.71',
    videoUrl: 'https://player.vimeo.com/video/947046740?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479',
    videoThumbnailUrl: 'https://i.vimeocdn.com/video/1853440971-50a8a1d4d9d5983e2ca0ee4b88d058f6e4633d9d99213e76dbe554008410e499-d_1280x720?r=pad',
    imageUrl: 'https://withjoy.com/assets/public/messaging/partnerships/paperlust/invitation-suites/harmony-suite/harmony-suite-1-main.jpg?v=2',
    descriptionHighlights: [
      {
        text:
          'The Harmony set is a perfect balance between wedding neutrals and greenery. With stunning script fonts and quality print and paper, this layered invitation set is bound to set the tone for your big day'
      },
      { text: 'Matching double-sided information card that is big enough for all your wedding details' },
      { text: 'Small green name card that can be used as a guest name card to tie in a hint of green to this suit' }
    ],
    suiteHighlights: [
      { text: 'Personalization with Joy concierge design services' },
      { text: 'Wedding invitation card, 5”x7” arch on Premium 300 GSM Cotten Paper' },
      { text: 'Details card,  4.2” x 5.5” half arch on 300 GSM cotton paper' },
      { text: 'Guest name card,  3.1” x 3.7”' },
      { text: 'Premium grey envelopes' },
      HIGHLIGHT_ENVELOPE_ADDRESSING
    ]
  },
  {
    id: uuid(),
    identifier: 'harmonyGatefold',
    shortName: 'Harmony Gatefold',
    longName: 'Harmony Gatefold Wedding Invitation Suite',
    costPerHundredString: '$4.29',
    videoUrl: 'https://player.vimeo.com/video/947044716?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479',
    videoThumbnailUrl: 'https://i.vimeocdn.com/video/1853438016-64478fea48afc207f85486f7ad8248b0412f8a688d61e3e4c10d91036a8c4013-d_1280x720?r=pad',
    imageUrl: 'https://withjoy.com/assets/public/messaging/partnerships/paperlust/invitation-suites/harmony-gatefold/harmony-gatefold-1-main.jpg?v=2',
    descriptionHighlights: [
      { text: 'The Harmony gatefold invitations are a perfect way to send your guests all the info they need for the day' },
      { text: 'High quality photo printing on the outside' },
      { text: 'The inside of this all in one gatefold invitation includes all your wedding day finer details, RSVP, and wedding timeline' },
      { text: 'Harvest color envelopes included with each invitation' }
    ],
    suiteHighlights: [
      { text: 'Personalization with Joy concierge design services' },
      { text: 'Gatefold Wedding Invitation, 10” x 7” on premium 380 GSM paper' },
      { text: 'Premium harvest color envelopes' },
      HIGHLIGHT_ENVELOPE_ADDRESSING
    ]
  },
  {
    id: uuid(),
    identifier: 'empireNow',
    shortName: 'Empire Now',
    longName: 'Empire Now Wedding Invitation Suite',
    costPerHundredString: '$4.26',
    videoUrl: 'https://player.vimeo.com/video/947044156?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479',
    videoThumbnailUrl: 'https://i.vimeocdn.com/video/1853437219-35b71461829d0e6f7be76a171df3842831ec96090781b290714cc573a26e43c1-d_1280x720?r=pad',
    imageUrl: 'https://withjoy.com/assets/public/messaging/partnerships/paperlust/invitation-suites/empire-now/empire-now-gatefold.jpg?v=2',
    descriptionHighlights: [
      { text: 'The Empire Now all-in-one Gatefold design is a combination of curvy die-cut and elegant typography' },
      { text: 'This all-in-one wedding invitation with high quality photo printing includes all your wedding day finer details and RSVP information in one elegant card' }
    ],
    suiteHighlights: [
      { text: 'Personalization with Joy concierge design services' },
      { text: 'Gatefold Wedding Invitation, 11.8” x 7” side wave on premium 380 GSM paper' },
      { text: 'Premium black envelopes with white ink' },
      HIGHLIGHT_ENVELOPE_ADDRESSING
    ]
  },
  {
    id: uuid(),
    identifier: 'byronGatefold',
    shortName: 'Byron Gatefold',
    longName: 'Byron Gatefold Wedding Invitation Suite',
    costPerHundredString: '$4.29',
    videoUrl: 'https://player.vimeo.com/video/947043616?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479',
    videoThumbnailUrl: 'https://i.vimeocdn.com/video/1853436469-c1c8a5f3e50f5a917876fdbd74b67dd613cb6505c14702b1c5a0dd0437820abd-d_1280x720?r=pad',
    imageUrl: 'https://withjoy.com/assets/public/messaging/partnerships/paperlust/invitation-suites/byron-gatefold/byron-gatefold.jpg?v=2',
    descriptionHighlights: [
      { text: 'All your invitation, RSVP, and wedding details in one gatefold card' },
      { text: 'Customize with your own photo on the inside' },
      { text: 'This all-in-one wedding invitation includes all your wedding day finer details and RSVP information' }
    ],
    suiteHighlights: [
      { text: 'Personalization with Joy concierge design services' },
      { text: 'Gatefold Wedding Invitation, 10” x 7” on premium 380 GSM paper' },
      { text: 'Premium almond color envelopes' },
      HIGHLIGHT_ENVELOPE_ADDRESSING
    ]
  }
].sort(() => Math.random() - 0.5);
