import React from 'react';
import { NavLink } from '@react-router';
import { Box, Flex } from '@withjoy/joykit';
import { animationTransition } from '@shared/utils/animationTransition';
import { useCardsRouterContext } from '@apps/card/Card.routes';
import { useTranslation } from '@shared/core';
import { useCardTelemetry } from '@apps/card/Card.telemetry';

const PrimaryNavItem: React.FC<{ to: string; exact?: boolean; onClick?: () => void }> = ({ to, exact, children, onClick }) => {
  return (
    <Box
      __css={{
        ['&.active > span']: {
          borderBottomColor: 'black'
        }
      }}
      paddingTop={1}
      paddingX={2}
      as={NavLink}
      onClick={onClick}
      exact={exact}
      to={to}
      typographyVariant={{ _: 'hed2', md: 'hed3' }}
    >
      <Box
        _hover={{ borderBottomColor: 'mono5' }}
        transition={animationTransition('border-bottom-color')}
        borderBottom={'1px solid transparent'}
        borderBottomWidth={{ _: '2px', md: '1px' }}
        as="span"
        paddingBottom={{ _: '11px', md: 1 }}
      >
        {children}
      </Box>
    </Box>
  );
};

export const PrimaryNav: React.FC = () => {
  const { getDraftsPath, getCardDashboardPath, getOrdersPath } = useCardsRouterContext();
  const { primaryNavInteracted } = useCardTelemetry();
  const { t } = useTranslation('stationery');

  const primaryNavTranslations = t('dashboard', 'primaryNav');

  return (
    <Flex columnGap={28} marginLeft={{ md: 8 }} borderBottom={{ _: '1px solid', md: 0 }} borderBottomColor={'mono3'} paddingX={4} height={{ _: 9, md: 0 }} alignItems="center">
      <PrimaryNavItem
        exact
        to={getCardDashboardPath()}
        onClick={() => {
          primaryNavInteracted('home');
        }}
      >
        {primaryNavTranslations.home()}
      </PrimaryNavItem>
      <PrimaryNavItem
        to={getDraftsPath()}
        onClick={() => {
          primaryNavInteracted('drafts');
        }}
      >
        {primaryNavTranslations.drafts()}
      </PrimaryNavItem>
      <PrimaryNavItem
        to={getOrdersPath()}
        onClick={() => {
          primaryNavInteracted('orders');
        }}
      >
        {primaryNavTranslations.orders()}
      </PrimaryNavItem>
    </Flex>
  );
};
