import React from 'react';
import { PrintJobDetails } from '@apps/card/components/PrintJobDetails';
import { Flex, TextV2 } from '@withjoy/joykit';
import { useStationeryPrintOrderByOrderNumberQuery } from '@graphql/generated';
import { Layout } from '@apps/card/components/Layout';
import { SkeletonGroup } from '@shared/components/Skeleton';
import { pxToRem } from '@withjoy/joykit/theme';
import { PrimaryNav } from '../Dashboard/components/PrimaryNav';
import { useCardsRouterContext } from '@apps/card/Card.routes';
import { useIsMobileOrTablet } from '@shared/utils/media/useMediaScreens';
import { ScrollToTop } from '@apps/common/components/ScrollToTop/ScrollToTop';
import { PrintJobDetailsTelemetryProxyProvider } from '../Dashboard/components/PrintJobDetailsTelemetryProxyProvider';
import { useCardTelemetry } from '@apps/card/Card.telemetry';

interface OrderDetailsProps {
  printOrderNumber: string;
  disableTelemetry?: boolean;
}

export const OrderDetails = ({ printOrderNumber, disableTelemetry }: OrderDetailsProps) => {
  const { data } = useStationeryPrintOrderByOrderNumberQuery({
    variables: {
      orderNumber: printOrderNumber
    },
    batchMode: 'fast'
  });

  return (
    <SkeletonGroup maxWidth="100%" isReady={!!data?.stationeryPrintOrderByOrderNumber}>
      {data?.stationeryPrintOrderByOrderNumber && (
        <Flex flexDirection="column" rowGap={{ _: 8, md: 10 }} width={{ _: '100%', md2: 1080 }} maxWidth="100%">
          <TextV2 typographyVariant={{ _: 'HedMobile32', md: 'Hed56' }} paddingX={{ _: 6, sm2: 8, md: 0 }}>
            Order Summary
          </TextV2>
          {disableTelemetry ? (
            <PrintJobDetails variant="detailed" order={data.stationeryPrintOrderByOrderNumber} />
          ) : (
            <PrintJobDetailsTelemetryProxyProvider printJobDetailsViewVariant="detailed" order={data.stationeryPrintOrderByOrderNumber}>
              <PrintJobDetails variant="detailed" order={data.stationeryPrintOrderByOrderNumber} />
            </PrintJobDetailsTelemetryProxyProvider>
          )}
        </Flex>
      )}
    </SkeletonGroup>
  );
};

export const OrderDetailsRoute = (props: OrderDetailsProps) => {
  const { printOrderNumber } = props;

  const { getCardDashboardPath } = useCardsRouterContext();
  const isMobileOrTablet = useIsMobileOrTablet();

  useCardTelemetry({ page: 'orderDetailsRoute' });

  return (
    <Layout.Grid>
      <ScrollToTop />
      <Layout.Main>
        <Layout.Header alignment="flex-start" title="Paper Cards" titleRedirectPath={getCardDashboardPath()}>
          {!isMobileOrTablet && <PrimaryNav />}
        </Layout.Header>
        {isMobileOrTablet && <PrimaryNav />}
        <Flex justifyContent="center" paddingTop={10} paddingBottom={pxToRem(97)}>
          <OrderDetails printOrderNumber={printOrderNumber} />
        </Flex>
      </Layout.Main>
    </Layout.Grid>
  );
};
