import { CatalogTreeRootInput, EventType } from '@graphql/generated';
import { deepmerge } from '@shared/utils/deepmerge';

import { ValuesPerEnvironment, extractConfigValuesBasedOnEnvironment } from './configEnvironment';
import { BABY_SHOP_PATH, CATALOG_PATH } from '@apps/registry/common/components/Catalog/Catalog.constants';

export interface EventTypeConfig {
  eventDashboardEnabled: boolean;
  exampleConfigUsagePhoto: string;
  exampleConfigUsageDefaultCatalogTreeRoot: CatalogTreeRootInput;
  registryGiftCountAdvisorEnabled: boolean;
  registryDashboardLinkEnabled: boolean;
  checklistInGiftBasketWidgetEnabled: boolean;
  registryOverviewOverviewBillboardSliderEnabled: boolean;
  registryOverviewChecklistEnabled: boolean;
  registryOverviewGiftCountReg101Enabled: boolean;
  registryOverviewOverviewCarouselEnabled: boolean;
  registryOverviewRecommendedProductsSectionEnabled: boolean;
  registryOverviewJoyQuickAddButtonImageUrl: string;
  registryMobileAppEnabled: boolean;
  registryOverviewDepartmentShowcaseEnabled: boolean;
  registryQuickAddVideo2: string;
  registryQuickAddVideo3: string;
  registryAdminManageLink: string;
  registryAdminShopLink: string;
  registryStarterPacks: boolean;
  registryOnlyEnableLinkingExternalRegistries: boolean;
  registryOverviewFAQs: boolean;
  registryPerks: boolean;
  universalRegistryToolsV2: boolean;
  registryConsultation: boolean;
  overviewBillboardCurationsEnabled: boolean;
  registryOverviewProductRecommendations: boolean;
  /**
   * Whether the user can add external registries.
   */
  externalRegistriesEnabled: boolean;
  externalRegistryHelpLink: string;
  impactRadiusCampaignId: number;
  impactRadiusActionTrackerId: number;
  impactRadiusCreateEventActionTrackerId: number;
  bottomCategoryShowcase: boolean;
  /**
   * Whether the user can see the subcategory highlights.
   */
  subCatHighlights: boolean;
  /**
   * Guest site SEO data
   */
  guestSitePageTitle: (eventDisplayName: string, userInfo: { partnerOneFirstName: string; partnerOneLastName: string; partnerTwoFirstName: string }) => string;
  guestSitePageDescription: (
    eventDisplayName: string,
    userInfo: { partnerOneFirstName: string; partnerOneLastName: string; partnerTwoFirstName: string },
    keywords: string
  ) => string;
}

type ConfigPerEventType = {
  [K in EventType]: K extends EventType.general ? ValuesPerEnvironment<EventTypeConfig> : Partial<ValuesPerEnvironment<EventTypeConfig>>;
};

const configPerEventType: ConfigPerEventType = {
  general: {
    eventDashboardEnabled: true,
    exampleConfigUsagePhoto: 'https://withjoy.com/assets/public/marcom-prod/registry-v2/registry-hero/registry-hero-static-v3.jpg?opt=aggressive&ver=2',
    exampleConfigUsageDefaultCatalogTreeRoot: CatalogTreeRootInput.default,
    registryDashboardLinkEnabled: true,
    registryGiftCountAdvisorEnabled: true,
    checklistInGiftBasketWidgetEnabled: false,
    registryOverviewOverviewBillboardSliderEnabled: true,
    registryOverviewChecklistEnabled: true,
    registryOverviewGiftCountReg101Enabled: false,
    registryOverviewOverviewCarouselEnabled: true,
    registryOverviewRecommendedProductsSectionEnabled: false,
    registryOverviewJoyQuickAddButtonImageUrl: 'https://withjoy.com/assets/public/apps/registry/overview/joy-button@3x.jpg',
    registryMobileAppEnabled: true,
    registryOverviewDepartmentShowcaseEnabled: false,
    externalRegistriesEnabled: true,
    registryQuickAddVideo2: 'https://withjoy.com/assets/public/apps/registry/quick-add/AddButton_Chrome_Animation2_v3_enhanced.webm',
    registryQuickAddVideo3: 'https://withjoy.com/assets/public/apps/registry/quick-add/AddButton_Chrome_Animation3_v3_enhanced.webm',
    registryAdminManageLink: '/edit/registry',
    registryAdminShopLink: CATALOG_PATH,
    registryStarterPacks: false,
    registryOnlyEnableLinkingExternalRegistries: false,
    registryOverviewFAQs: false,
    registryPerks: false,
    universalRegistryToolsV2: false,
    registryConsultation: false,
    externalRegistryHelpLink: 'https://help.withjoy.com/knowledge-base/adding-an-external-registry-to-joy',
    impactRadiusCampaignId: 13168,
    impactRadiusActionTrackerId: 24458,
    impactRadiusCreateEventActionTrackerId: 24457,
    overviewBillboardCurationsEnabled: true,
    registryOverviewProductRecommendations: false,
    bottomCategoryShowcase: false,
    subCatHighlights: false,
    guestSitePageTitle: (eventDisplayName: string, userInfo: { partnerOneFirstName: string; partnerOneLastName: string; partnerTwoFirstName: string }) =>
      eventDisplayName ? `${eventDisplayName} | Joy` : 'Joy',
    guestSitePageDescription: (eventDisplayName: string, userInfo: { partnerOneFirstName: string; partnerOneLastName: string; partnerTwoFirstName: string }) =>
      eventDisplayName ? `Welcome to ${eventDisplayName}! Explore photos, event information, and more on Joy.` : 'Welcome! Explore photos, event information, and more on Joy.'
  },
  wedding: {
    eventDashboardEnabled: true,
    exampleConfigUsagePhoto: 'https://withjoy.com/assets/public/marcom-prod/registry-v2/zero-fee-cash-gifting/image-zero-fee-2.jpg?opt=aggressive&ver=2',
    exampleConfigUsageDefaultCatalogTreeRoot: CatalogTreeRootInput.curations,
    checklistInGiftBasketWidgetEnabled: true,
    registryQuickAddVideo2: 'https://withjoy.com/assets/public/apps/registry/quick-add/AddButton_Chrome_Animation2_v3_enhanced.webm',
    registryQuickAddVideo3: 'https://withjoy.com/assets/public/apps/registry/quick-add/AddButton_Chrome_Animation3_v3_enhanced.webm',
    registryStarterPacks: true,
    registryOverviewFAQs: true,
    registryPerks: true,
    universalRegistryToolsV2: true,
    registryConsultation: true,
    registryOverviewProductRecommendations: true,
    bottomCategoryShowcase: true,
    subCatHighlights: true,
    guestSitePageTitle: (eventDisplayName: string, userInfo: { partnerOneFirstName: string; partnerOneLastName: string; partnerTwoFirstName: string }) => {
      let title = eventDisplayName;
      if (!eventDisplayName) {
        if (userInfo.partnerOneFirstName && userInfo.partnerTwoFirstName) {
          title = `${userInfo.partnerOneFirstName} & ${userInfo.partnerTwoFirstName}`;
        } else if (userInfo.partnerOneFirstName && userInfo.partnerOneLastName) {
          title = userInfo.partnerOneFirstName + ' ' + userInfo.partnerOneLastName;
        } else if (userInfo.partnerOneFirstName) {
          title = userInfo.partnerOneFirstName;
        }
      }
      return title ? `${title} | Joy` : 'Joy';
    },
    guestSitePageDescription: (eventDisplayName: string, userInfo: { partnerOneFirstName: string; partnerOneLastName: string; partnerTwoFirstName: string }, keywords: string) => {
      let title = '';
      if (userInfo.partnerOneFirstName && userInfo.partnerTwoFirstName) {
        title = `${userInfo.partnerOneFirstName} and ${userInfo.partnerTwoFirstName}`;
      } else if (eventDisplayName) {
        title = eventDisplayName;
      } else if (userInfo.partnerOneFirstName && userInfo.partnerOneLastName) {
        title = userInfo.partnerOneFirstName + ' ' + userInfo.partnerOneLastName;
      } else if (userInfo.partnerOneFirstName) {
        title = userInfo.partnerOneFirstName;
      }
      return title ? `Welcome to the website for ${title}! Explore photos, ${keywords}, and more on Joy.` : `Welcome! Explore photos, ${keywords}, and more on Joy.`;
    }
  },
  babyRegistry: {
    eventDashboardEnabled: false,
    exampleConfigUsagePhoto: 'https://thumbor.forbes.com/thumbor/fit-in/x/https://www.forbes.com/health/wp-content/uploads/2022/03/baby-eating-food.jpeg.jpg',
    exampleConfigUsageDefaultCatalogTreeRoot: {
      dev: CatalogTreeRootInput.default,
      stage: CatalogTreeRootInput.curations,
      prod: CatalogTreeRootInput.default
    },
    checklistInGiftBasketWidgetEnabled: true,
    registryDashboardLinkEnabled: false,
    registryGiftCountAdvisorEnabled: false,
    registryOverviewOverviewBillboardSliderEnabled: false,
    registryOverviewOverviewCarouselEnabled: false,
    registryOverviewRecommendedProductsSectionEnabled: true,
    registryOverviewJoyQuickAddButtonImageUrl: 'https://withjoy.com/assets/public/apps/registry/overview/joy-quick-add@3x.jpg',
    registryMobileAppEnabled: false,
    registryOverviewDepartmentShowcaseEnabled: true,
    externalRegistriesEnabled: false,
    registryQuickAddVideo2: 'https://withjoy.com/assets/public/apps/registry/quick-add/AddButton_Chrome_Baby_Animation2_v1.mp4',
    registryQuickAddVideo3: 'https://withjoy.com/assets/public/apps/registry/quick-add/AddButton_Chrome_Baby_Animation3_v1.mp4',
    registryAdminManageLink: '/edit/registry/manage',
    registryAdminShopLink: BABY_SHOP_PATH,
    registryOnlyEnableLinkingExternalRegistries: false,
    externalRegistryHelpLink: 'https://help.withjoy.com/baby-registry/adding-external-baby-registries-to-joy',
    impactRadiusCampaignId: 21345,
    impactRadiusActionTrackerId: 42057,
    impactRadiusCreateEventActionTrackerId: 42056,
    overviewBillboardCurationsEnabled: false,
    guestSitePageTitle: (eventDisplayName: string, userInfo: { partnerOneFirstName: string; partnerOneLastName: string; partnerTwoFirstName: string }) =>
      eventDisplayName ? `${eventDisplayName} | Baby Registry | Joy` : 'Baby Registry | Joy',
    guestSitePageDescription: (eventDisplayName: string, userInfo: { partnerOneFirstName: string; partnerOneLastName: string; partnerTwoFirstName: string }) =>
      eventDisplayName
        ? `Welcome to ${eventDisplayName}! Explore photos, baby registry information, and more on Joy.`
        : 'Welcome! Explore photos, baby registry information, and more on Joy.'
  }
};

let builtConfigs: Record<EventType, EventTypeConfig>;
export function getEventTypeConfig(eventType: EventType): EventTypeConfig {
  if (!builtConfigs) {
    builtConfigs = {} as Record<EventType, EventTypeConfig>;
    const generalConfig = extractConfigValuesBasedOnEnvironment<EventTypeConfig>(configPerEventType.general);
    Object.entries(configPerEventType).forEach(([eventType, eventTypeConfig]) => {
      if (eventType === EventType.general) {
        builtConfigs[eventType] = generalConfig;
        return;
      }

      builtConfigs[eventType as EventType] = deepmerge(generalConfig, extractConfigValuesBasedOnEnvironment<Partial<EventTypeConfig>>(eventTypeConfig));
    });
  }

  return builtConfigs[eventType];
}
