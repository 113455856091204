import { css, styled, Box, StyleSystemProps, ModalProps, SpacingStack, DialogV2 } from '@withjoy/joykit';
import { animated } from 'react-spring';
import { backgroundImageForUrl } from '@shared/utils/style/backgroundImage';

type FocalPointProps = {
  isActive?: boolean;
  isMobile?: boolean;
};

export const dialogOverrides: NonNullable<ModalProps['overrides']> = {
  Content: {
    props: {
      minHeight: 'auto',
      width: '600px'
    }
  }
};

export const dialogSmallDesktopOverrides: NonNullable<ModalProps['overrides']> = {
  Content: {
    props: {
      maxHeight: 'calc(100vh - 48px)',
      width: '600px'
    }
  },
  ContentContainer: {
    props: {
      paddingTop: 0,
      paddingBottom: 0
    }
  }
};

export const dialogOverridesTablet: NonNullable<ModalProps['overrides']> = {
  Root: {
    props: {
      overflow: 'inherit'
    }
  },
  ContentContainer: {
    props: {
      alignItems: 'center'
    }
  },
  Content: {
    props: {
      minHeight: 'auto',
      width: '600px'
    }
  }
};

export const dialogOverridesMobile: NonNullable<ModalProps['overrides']> = {
  ContentContainer: {
    props: {
      alignItems: 'flex-end'
    }
  },
  Content: {
    props: {
      minHeight: 'auto',
      margin: 0,
      flexGrow: 0
    }
  }
};

export const DialogContainer = styled(DialogV2)`
  ${props => props.theme.mediaQueries.between({ breakpointAlias: 'md' }, { breakpointAlias: 'md3' })} {
    height: 100%;
  }
`;
export const DialogBody = styled(DialogV2.Body)`
  ${props => props.theme.mediaQueries.between({ breakpointAlias: 'md' }, { breakpointAlias: 'md3' })} {
    display: flex;
    align-items: center;
    flex-direction: column;
  }
`;
export const DialogContent = styled(SpacingStack)`
  ${props => props.theme.mediaQueries.between({ breakpointAlias: 'md' }, { breakpointAlias: 'md3' })} {
    height: 100%;
    flex: 1 1 auto;
  }
`;
export const StyledCloseIcon = styled(Box)``;
export const StyledImage = styled(Box)<{ backgroundImage: string; maxWidth: number; maxHeight: number }>`
  background-image: ${props => backgroundImageForUrl(props.backgroundImage)};
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  width: 100%;
  height: 100%;
  max-width: ${props => `${props.maxWidth}px`};
  max-height: ${props => `${props.maxHeight}px`};
  ${props => props.theme.mediaQueries.between({ breakpointAlias: 'md' }, { breakpointAlias: 'md3' })} {
    max-height: 100%;
  }
`;
export const ImageContainer = styled(Box)<{ aspectRatio: string | number }>`
  aspect-ratio: ${props => props.aspectRatio};
  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) and (orientation: landscape) {
    max-height: 408px;
  }
  ${props => props.theme.mediaQueries.between({ breakpointAlias: 'md' }, { breakpointAlias: 'md3' })} {
    flex: 1 1 auto;
  }
`;
export const FocalPoint = styled(Box)``;
export const Grid = styled(Box)``;
export const Cell = styled(Box)<FocalPointProps>`
  ${({ isActive, isMobile }) =>
    isActive &&
    css`
      ${FocalPoint} {
        box-shadow: '1px 0px 6px 4px #21212129';
        &::after {
          content: '';
          display: block;
          position: absolute;
          border-radius: 50%;
          width: ${isMobile ? '40px' : '48px'};
          height: ${isMobile ? '40px' : '48px'};
          border: 2px solid white;
          filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.24));
        }
      }
    `}
`;
export const HighlightMask = styled(animated(Box))`
  filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.24));
  mix-blend-mode: overlay;
`;

const highlightStyles: StyleSystemProps = {
  position: 'absolute',
  width: ['40px', '48px'],
  height: ['40px', '48px'],
  backgroundColor: 'white',
  borderRadius: '50%',
  boxShadow: ['0px 0px 56px 104px #ffffff', '0px 0px 80px 160px #ffffff']
};

const imageContainerStyles: StyleSystemProps = {
  position: 'relative',
  overflow: 'hidden',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%'
};

const gridStyles: StyleSystemProps = {
  display: 'grid',
  justifyContent: 'center',
  gridTemplateColumns: 'repeat(3, 1fr)',
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  background: 'rgba(0, 0, 0, 0.48)',
  borderRadius: '4px'
};

const cellStyles: StyleSystemProps = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'pointer'
};

export const focalPointStyles: StyleSystemProps = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: ['12px', '16px'],
  height: ['12px', '16px'],
  borderRadius: '50%',
  backgroundColor: 'white',
  border: '0 none',
  boxShadow: '1px 0px 6px 4px #21212129'
};

const imageStyles: StyleSystemProps = {
  borderRadius: ['inherit', '4px']
};

const buttonContainerStyles: StyleSystemProps = {
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  marginTop: 4
};

export const styles = {
  buttonContainer: buttonContainerStyles,
  cell: cellStyles,
  focalPoint: focalPointStyles,
  grid: gridStyles,
  highlight: highlightStyles,
  image: imageStyles,
  imageContainer: imageContainerStyles
};
